import * as React from 'react';
import { H2 } from 'style/text';
import { device } from 'style/device';

import styled from 'styled-components';
import { CloseIcon } from './Icon';

const Overlay = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: ${({ theme }) => theme.colors.transparentGrey};
  z-index: 1000;
`;

const Dialog = styled.div`
  background: #fff;
  border-radius: ${({ theme }) => theme.borderRadius}px;
  border: 1px solid ${({ theme }) => theme.colors.border};

  @media ${device.minMobile} {
    min-width: 350px;
  }
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid ${({ theme }) => theme.colors.border};
  padding: 16px;
`;

const CloseButton = styled.button`
  border: none;
  outline: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  color: ${({ theme }) => theme.colors.primaryDark};
  background-color: transparent;
  padding: 4px;

  &:hover {
    color: ${({ theme }) => theme.colors.primaryLight};
  }
`;

const Content = styled.div`
  padding: 16px;
  max-width: 70ch;
`;

const Footer = styled.div`
  @media ${device.minMobile} {
    display: flex;
  }
  justify-content: space-around;
  gap: 8px;
  border-top: 1px solid ${({ theme }) => theme.colors.border};
  padding: 16px;
`;

type ModalProps = React.PropsWithChildren<{
  title: string;
  onClose: () => void;
  actions?: React.ReactNode[] | React.ReactNode;
}>;

const Modal = ({ title, onClose, actions, children }: ModalProps) => {
  const close = () => {
    onClose();
  };

  return (
    <Overlay>
      <Dialog>
        <Header>
          <H2 style={{ margin: 0 }}>{title}</H2>
          <CloseButton onClick={close}>
            <CloseIcon size="medium" />
          </CloseButton>
        </Header>
        <Content>{children}</Content>
        {actions && <Footer>{actions}</Footer>}
      </Dialog>
    </Overlay>
  );
};

export default Modal;
