import * as React from 'react';
import AppContext from 'AppContext';
import I18nContext from 'I18nContext';
import { Redirect } from 'react-router-dom';
import { getUrlParams } from 'utils';
import * as popa from 'api/popa';
import { getUser } from 'api/cee';
import { POPA } from 'api/popa/types';
import PageLoader from 'components/PageLoader';
import { getAuth, signInWithCustomToken } from 'firebase/auth';
import { TokensData } from 'types/types';

function Login() {
  const { href, origin, pathname } = window.location;

  const { code } = getUrlParams(href);
  const {
    firebaseApp,
    setTokens: saveTokensToAppState,
    tokens: appStateTokens,
    readyToRender,
    appUser,
  } = React.useContext(AppContext);
  const { strings } = React.useContext(I18nContext);
  const [tokens, setTokens] = React.useState<TokensData | null>(null);
  const [isSignedInToFirebase, setSignedInToFirebase] = React.useState(false);
  const fetchingTokens = React.useRef(false);

  React.useEffect(() => {
    async function exchangeAccessKeyForTokens() {
      if (code && !tokens && !appUser && !fetchingTokens.current) {
        fetchingTokens.current = true;
        const data = await popa.getTokensFromTempToken(code);
        if (data) {
          const user = await getUser({ accessToken: data.accessToken });
          setTokens({
            ...data,
            ...user,
          });
        }
      }
      if (appUser) {
        setTokens(appStateTokens as TokensData);
      }
    }

    exchangeAccessKeyForTokens();
  }, [code, appStateTokens, appUser, setTokens, tokens]);

  React.useEffect(() => {
    if (appUser) {
      if (!tokens?.accessToken && !tokens?.firebaseCustomToken && readyToRender) {
        const returnUrl = origin + pathname;
        window.location.href = popa.getRedirectToLoginUrl(returnUrl);
      }
    } else {
      if (!code && !tokens) {
        const returnUrl = origin + pathname;
        window.location.href = popa.getRedirectToLoginUrl(returnUrl);
      }
    }
  }, [code, appUser, origin, pathname, readyToRender, tokens]);

  React.useEffect(() => {
    async function asyncSignInToFirebase() {
      const auth = getAuth(firebaseApp);
      const credentials = await signInWithCustomToken(auth, tokens.firebaseCustomToken);
      if (credentials) {
        setSignedInToFirebase(true);
      }
    }
    if (tokens) {
      if (tokens.firebaseCustomToken) {
        asyncSignInToFirebase();
      } else {
        throw new Error('Authentication failed.');
      }
    }
  }, [tokens, firebaseApp]);

  React.useEffect(() => {
    // We are signed in to everything, save the tokens to the app state
    if (tokens && isSignedInToFirebase) {
      saveTokensToAppState(tokens);
    }
  }, [tokens, saveTokensToAppState, isSignedInToFirebase]);

  if (tokens?.accessToken && tokens.firebaseCustomToken && isSignedInToFirebase) {
    return <Redirect to={pathname} />;
  }

  return (
    <PageLoader
      heading={code || tokens?.accessToken ? strings.loggingIn : strings.redirecting}
      description={strings.justASec}
    />
  );
}

export default Login;
