import React from 'react';

export type Props = {
  strings: Record<string, string>;
};

const context: React.Context<Props> = React.createContext({
  strings: {},
});

export default context;
