import * as React from 'react';
import { useSnackbar } from 'notistack';
import * as ceeV3 from 'api/cee/cee-v3';
import { CEE } from 'api/cee/types';
import I18nContext from 'I18nContext';
import AppContext from 'AppContext';
import Button, { COLOR } from './Button';
import Modal from './Modal';
import styled from 'styled-components';
import { toDateString } from 'utils';

const Email = styled.a`
  color: ${({ theme }) => theme.colors.primary};
`;

const Bold = styled.span`
  font-family: ${({ theme }) => theme.fonts.bold};
`;

export type Input = {
  subscriptionId: string;
  productName: string;
  validUntil: string;
};
type Props = {
  product: CEE.PersonProductV3;
  onCancel: () => void;
  onDone: () => void;
};

const ClubSubscriptionModal = ({ product, onCancel, onDone }: Props) => {
  const { tokens } = React.useContext(AppContext);
  const { strings } = React.useContext(I18nContext);
  const [loading, setLoading] = React.useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const cancelSubscription = async () => {
    if (tokens?.accessToken) {
      setLoading(true);

      try {
        await ceeV3.cancelPersonSubscriptionProduct(
          tokens.PersonId,
          product.Product.Id,
          tokens.accessToken
        );

        onDone();
        enqueueSnackbar(strings.subscriptionCancelledSuccess, { variant: 'success' });
      } catch (error) {
        enqueueSnackbar(strings.subscriptionCancelledError, { variant: 'error' });
      }

      setLoading(false);
    }
  };

  return (
    <Modal
      onClose={onCancel}
      title={strings.cancelProduct.replace('{{product}}', product.Product.DisplayName)}
      actions={[
        <Button key="continueWithBtn" color={COLOR.OUTLINED} onClick={onCancel} disabled={loading}>
          {strings.continueProduct.replace('{{product}}', product.Product.DisplayName)}
        </Button>,
        <br key="3" />,
        <Button
          key="cancelSubBtn"
          color={COLOR.DANGER}
          onClick={cancelSubscription}
          loading={loading}
        >
          {strings.cancelProduct.replace('{{product}}', product.Product.DisplayName)}
        </Button>,
      ]}
    >
      <>
        <p>{strings.confirmCancelText1}</p>
        <p>
          {strings.confirmCancelText2}{' '}
          <Bold>{toDateString(product.SubscriptionNextRenewalDate)}</Bold>.
        </p>
        <p>
          {strings.confirmCancelText3
            .replace('{{product}}', product.Product.DisplayName)
            .replace('{{subscription}}', strings.subscription)}
        </p>
        <p>
          {strings.confirmCancelText4}{' '}
          <Email href={`mailto: ${strings.supportEmail}`}>{strings.supportEmail}</Email>
        </p>
      </>
    </Modal>
  );
};

export default ClubSubscriptionModal;
