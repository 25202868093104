import * as React from 'react';
import { useMediaQuery } from 'react-responsive';
import styled from 'styled-components';
import { device } from 'style/device';
import { HomeIcon, MailIcon } from 'components/Icon';
import logo from './logo.svg';
import I18nContext from 'I18nContext';

export const Height = 55;

const BorderColor = '#ede9f4';

const Container = styled.footer`
  position: fixed;
  bottom: 0;
  width: 100vw;
  height: ${Height}px;
  border-top: 1px solid ${BorderColor};
  background-color: #fff;

  color: ${({ theme }) => theme.colors.primary};
`;

const Content = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  max-width: 331px;
  margin: 0 auto;

  @media ${device.minTablet} {
    max-width: 600px;
  }

  @media ${device.minDesktop} {
    max-width: 828px;
  }
`;

const Text = styled.span`
  font-size: 10.5px;
  line-height: 14px;
  font-family: ${({ theme }) => theme.fonts.bold};
  text-transform: uppercase;
`;

const IconLink = styled.a`
  display: flex;
  align-items: center;
  > ${Text} {
    margin-left: 1ch;
  }
`;

const Divider = styled.div`
  border-left: 1px solid ${BorderColor};
  height: 21px;
  margin: 0 14px;

  @media ${device.minTablet} {
    margin: 0 30px;
  }
`;

const Image = styled.img`
  height: 19px;

  @media ${device.minTablet} {
    height: 25px;
  }
`;

const MailButton = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid ${BorderColor};
  border-radius: 50%;
  padding: 6px;
`;

function Footer() {
  const { strings } = React.useContext(I18nContext);
  const isTabletOrBigger = useMediaQuery({ query: device.minTablet });
  const isDesktop = useMediaQuery({ query: device.minDesktop });
  const website = new URL(strings.website).hostname;

  return (
    <Container>
      <Content>
        <Image alt="SEF Fotbollskonto" src={logo} />
        <Divider />
        <Text>{strings.copy}</Text>
        <Divider />
        {!isTabletOrBigger && (
          <MailButton target="_blank" href={`mailto:${strings.supportEmail}`}>
            <MailIcon />
          </MailButton>
        )}
        {isTabletOrBigger && (
          <IconLink target="_blank" href={`mailto:${strings.supportEmail}`}>
            <MailIcon />
            <Text>{strings.supportEmail}</Text>
          </IconLink>
        )}
        {isDesktop && (
          <>
            <Divider />
            <IconLink target="_blank" href={strings.website}>
              <HomeIcon />
              <Text>{website}</Text>
            </IconLink>
          </>
        )}
      </Content>
    </Container>
  );
}

export default Footer;
