import * as React from 'react';
import { useCallback } from 'react';
import styled from 'styled-components';
import { CEE } from 'api/cee/types';
import I18nContext from 'I18nContext';

const Container = styled.div`
  display: flex;
  align-items: center;
`;

const TextCompleted = styled.span`
  font-size: 13px;
  color: ${({ theme }) => theme.colors.green};
`;

const Text = styled.span`
  font-size: 13px;
`;

const Icon = styled.span`
  height: 12px;
  width: 12px;
  background-color: ${({ theme }) => theme.colors.green};
  border-radius: 50%;
  margin-right: 5px;

  &::after {
    content: ' ';
    display: inline-block;
    transform: rotate(45deg);
    height: 5px;
    width: 2.5px;
    margin-left: 4px;
    margin-bottom: 4px;
    border-bottom: 1.5px solid #fff;
    border-right: 1.5px solid #fff;
  }
`;

type Props = React.PropsWithChildren<{
  payment: CEE.Order;
}>;

const PaymentStatus = ({ payment }: Props) => {
  const { strings } = React.useContext(I18nContext);

  const getStatusString = useCallback(
    (status: string) => {
      switch (status) {
        case 'Initiated':
          return strings.initiated;
        case 'Completed':
          return strings.completed;
        default:
          return status;
      }
    },
    [strings]
  );

  return (
    <Container>
      {payment.Status === 'Completed' ? (
        <>
          <Icon />
          <TextCompleted>{getStatusString(payment.Status)}</TextCompleted>
        </>
      ) : (
        <Text>{getStatusString(payment.Status)}</Text>
      )}
    </Container>
  );
};

export default PaymentStatus;
