import * as React from 'react';
import ReactSelect, {
  Props,
  Theme,
  StylesConfig,
  OptionProps,
  components,
  ControlProps,
} from 'react-select';
import styled, { DefaultTheme, ThemeContext } from 'styled-components';

const OptionContainer = styled.div`
  display: flex;
  align-items: center;
  > * {
    &:first-child {
      margin-right: 8px;
    }
  }
`;

export type Option = { label: string; value: string; icon?: React.ReactNode };
const OptionWithIcon = (props: OptionProps<Option>) => {
  const { label, icon } = props.data;
  return (
    <components.Option {...props}>
      <OptionContainer>
        {icon}
        <span>{label}</span>
      </OptionContainer>
    </components.Option>
  );
};

const ControlWithIcon = (props: ControlProps<Option>) => {
  const { children, ...rest } = props;
  const icon = props.hasValue && !props.isMulti && props.getValue()[0]?.icon;
  return (
    <components.Control {...rest}>
      <span style={{ marginLeft: 8 }}>{icon}</span>
      {children}
    </components.Control>
  );
};

export type SelectProps = Props<Option>;
function Select(props: SelectProps) {
  const appTheme = React.useContext(ThemeContext) as DefaultTheme;

  const theme = (theme: Theme): Theme => ({
    ...theme,
    borderRadius: appTheme.borderRadius,
    spacing: {
      ...theme.spacing,
      controlHeight: 42,
      menuGutter: 1,
    },
    colors: {
      ...theme.colors,
      primary: appTheme.colors.selectOption.selected, // selected option
      primary25: appTheme.colors.selectOption.hover, // hover option
      neutral80: appTheme.colors.fields.text, // selected text
    },
  });

  const styles: StylesConfig<Option> = {
    control: (base, state) => ({
      ...base,
      boxShadow: 'none',
      fontSize: 13,
      fontFamily: appTheme.fonts.bold,
      minWidth: 160,
      borderColor: appTheme.colors.fields.border,
      backgroundColor: state.hasValue ? appTheme.colors.fields.background : 'transparent',
      ':hover': {
        borderColor: appTheme.colors.primary,
      },
      ':focus-within': {
        borderWidth: 2,
        borderColor: appTheme.colors.primary,
      },
    }),
    option: (base) => ({
      ...base,
      transition: `all ${appTheme.transitionTiming}`,
      color: 'inherit',
      ':active': {
        backgroundColor: appTheme.colors.selectOption.backgroundActive,
      },
    }),
  };

  return (
    <ReactSelect
      {...props}
      theme={theme}
      styles={styles}
      components={{ IndicatorSeparator: null, Option: OptionWithIcon, Control: ControlWithIcon }}
    />
  );
}

export default Select;
