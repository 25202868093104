import * as React from 'react';
import styled from 'styled-components';
import { useSnackbar } from 'notistack';
import Button from 'components/Button';
import Section from 'components/Section';
import Toggle from 'components/Toogle';
import I18nContext from 'I18nContext';
import AppContext from 'AppContext';
import * as cee from 'api/cee';
import { CEE } from 'api/cee/types';
import PageLoader from 'components/PageLoader';
import Select, { Option } from 'components/Select';
import { RouteComponentProps } from 'react-router-dom';
import Centered from 'components/Centered';

const List = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
`;

const Item = styled.li`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: ${({ theme }) => theme.borderRadius}px;
  border: 2px solid ${({ theme }) => theme.colors.border};
  background-color: #fff;
  color: ${({ theme }) => theme.colors.primaryDark};
  padding: 15px 16px;
  margin-bottom: 14px;
`;

const Sender = styled.span`
  font-family: ${({ theme }) => theme.fonts.bold};
`;

const LabeledToggle = styled.div`
  display: flex;
  align-items: center;

  :not(:last-child) {
    margin-bottom: 12px;
  }

  > label {
    margin-right: 6px;
  }
`;

type TeamOptions = {
  allsvenskan: Option[];
  superettan: Option[];
};

/**
 * For some reason putting this in CEE namespace doesn't work
 * Probably some typescript config..
 */
enum League {
  Allsvenskan = 1,
  Superettan = 2,
}

const toOption = (team: CEE.Team): Option => ({
  value: team.Id,
  label: team.DisplayName,
});

const Consents = (props: RouteComponentProps<any>) => {
  const { tokens } = React.useContext(AppContext);
  const { strings } = React.useContext(I18nContext);
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = React.useState(true);
  const [saving, setSaving] = React.useState(false);
  const [consents, setConsents] = React.useState<CEE.ConsentGroup[]>([]);
  const [avaialbleConsents, setAvailableConsents] = React.useState<CEE.Consent[]>([]);
  const [original, setOriginal] = React.useState<CEE.Consent[]>([]);

  React.useEffect(() => {
    async function fetchPersonConsents() {
      if (tokens?.accessToken) {
        const [grouped, mine, available] = await cee.getGroupedConsents(tokens.PersonId, {
          accessToken: tokens.accessToken,
        });
        setAvailableConsents(available);
        setConsents(grouped);
        setOriginal(mine);
        setLoading(false);
      }
    }
    fetchPersonConsents();
  }, [tokens]);

  const onConsentChange = (sender: string, channel: 'SMS' | 'EMAIL', value: boolean) => {
    if (!consents) return;
    const found = consents.find((g) => g.name === sender);
    if (!found) return;
    found[channel].consent = value;

    setConsents([...consents]);
  };

  const [selectedTeam, setSelectedTeam] = React.useState<Option | null>(null);
  const [supporter, setSupporter] = React.useState<CEE.Supporter | null>(null);
  const [teamsLoading, setTeamsLoading] = React.useState(true);
  const [teamOptions, setTeamOptions] = React.useState<TeamOptions>({
    allsvenskan: [],
    superettan: [],
  });

  React.useEffect(() => {
    async function fetchData() {
      if (tokens?.accessToken) {
        const teams = await cee.getTeams({ accessToken: tokens.accessToken });
        const allsvenskan = teams
          .filter((team) => team.League === League.Allsvenskan)
          .sort((a, b) => a.DisplayName.localeCompare(b.DisplayName))
          .map(toOption);
        const superettan = teams
          .filter((team) => team.League === League.Superettan)
          .sort((a, b) => a.DisplayName.localeCompare(b.DisplayName))
          .map(toOption);

        setTeamOptions({ allsvenskan, superettan });

        const supporter = await cee.getSupporter(tokens.PersonId, {
          accessToken: tokens.accessToken,
        });
        const favoriteTeamId = supporter.SupportedTeams[0]?.Id;
        const favoriteTeam =
          [...allsvenskan, ...superettan].find((t) => t.value === favoriteTeamId) || null;
        setSupporter(supporter);
        setSelectedTeam(favoriteTeam);
        setTeamsLoading(false);
      }
    }
    fetchData();
  }, [tokens]);

  const onTeamChange = (opt: Option) => {
    // You would think that CEE.Consent.TeamId would be the same as CEE.Team.Id
    // but you would be wrong...
    // I guess the word identifier was not clear enough.
    const found = consents.find((g) => g.name === opt.label);
    if (found) {
      found.SMS.consent = true;
      found.EMAIL.consent = true;
      setConsents([...consents]);
    } else {
      const email = avaialbleConsents.find((c) => c.Name === opt.label && c.Channel === 'EMAIL');
      const sms = avaialbleConsents.find((c) => c.Name === opt.label && c.Channel === 'SMS');
      if (email && sms) {
        const group: CEE.ConsentGroup = {
          name: opt.label,
          SMS: { ...sms, consent: true },
          EMAIL: { ...email, consent: true },
        };
        setConsents([group, ...consents]);
      }
    }

    setSelectedTeam(opt);
  };

  const onSave = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    if (saving || !tokens?.accessToken) return;

    setSaving(true);
    const auth = { accessToken: tokens.accessToken };
    const flat = consents.map((g) => [{ ...g.EMAIL }, { ...g.SMS }]).flat();
    const added = flat.filter((c) => !original.find((o) => o.ConsentId === c.ConsentId));
    const changed = flat
      .filter((c) => c.consent !== original.find((o) => o.ConsentId === c.ConsentId)?.consent)
      .filter((c) => !added.find((a) => a.ConsentId === c.ConsentId));

    await Promise.all([
      ...added.map((c) => cee.addConsent(tokens.PersonId, c, auth)),
      ...changed.map((c) => cee.updateConsent(tokens.PersonId, c, auth)),
    ]);

    if (supporter && supporter.SupportedTeams[0]?.Id !== selectedTeam?.value) {
      const toUpdate = {
        ...supporter,
        SupportedTeams: selectedTeam
          ? [{ Id: selectedTeam.value, DisplayName: selectedTeam.label }]
          : [],
      };

      try {
        const updated = await cee.updateSupporter(tokens.PersonId, toUpdate, auth);
        setSupporter(updated);
        enqueueSnackbar(strings.savedSuccess, { variant: 'success' });
      } catch (error) {
        enqueueSnackbar(strings.savedError, { variant: 'error' });
      }
    }

    setOriginal(flat);
    setSaving(false);
  };

  if (loading) {
    return <PageLoader />;
  }

  if (!consents) {
    return null;
  }

  return (
    <>
      <Section header={strings.favTeam} tooltip={strings.favTeamTooltip}>
        <Select
          isClearable
          value={selectedTeam}
          id="favoriteTeam"
          isLoading={teamsLoading}
          placeholder={teamsLoading ? strings.loading : strings.selectTeam}
          onChange={(option) => {
            const opt = option as Option | null;
            opt === null ? setSelectedTeam(null) : onTeamChange(opt);
          }}
          options={[
            {
              label: strings.allsvenskan,
              options: teamOptions.allsvenskan,
            },
            {
              label: strings.superettan,
              options: teamOptions.superettan,
            },
          ]}
        />
      </Section>
      <Section header={strings.sender} tooltip={strings.senderTooltip}>
        <List>
          {consents.map((group) => (
            <Item key={group.name}>
              <Sender>{group.name}</Sender>
              <div>
                <LabeledToggle>
                  <label>{strings.email}</label>
                  <Toggle
                    key={group.EMAIL.ConsentId}
                    checked={group.EMAIL.consent}
                    onChange={(e) =>
                      onConsentChange(group.name, group.EMAIL.Channel, e.target.checked)
                    }
                  />
                </LabeledToggle>
                <LabeledToggle>
                  <label>{strings.sms}</label>
                  <Toggle
                    key={group.SMS.ConsentId}
                    checked={group.SMS.consent}
                    onChange={(e) =>
                      onConsentChange(group.name, group.SMS.Channel, e.target.checked)
                    }
                  />
                </LabeledToggle>
              </div>
            </Item>
          ))}
        </List>
      </Section>
      <Centered>
        <Button onClick={onSave} loading={saving}>
          {strings.save}
        </Button>
      </Centered>
    </>
  );
};

export default Consents;
