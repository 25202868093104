import * as React from 'react';
import styled, { css } from 'styled-components';
import { H1, H2 } from 'style/text';
import { CEE } from 'api/cee/types';
import { POPA } from 'api/popa/types';
import { Height as HeaderHeight } from 'components/Header';
import { Height as FooterHeight } from 'components/Footer';
import Ticket from 'components/Tickets/Ticket';
import TicketDetails from 'components/Tickets/TicketDetails';
import { CloseIcon, LeftIcon } from 'components/Icon';
import { useTicketQrImages } from 'hooks/tickets';
import { device } from 'style/device';
import { useMediaQuery } from 'react-responsive';
import Spinner from 'components/Spinner';

const Overlay = styled.div<{ $hide?: boolean; $show?: boolean }>`
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  background: ${({ theme }) => theme.colors.transparentGrey};
  z-index: 1000;
  opacity: 0;

  ${(props) =>
    props.$show
      ? css`
          opacity: 1;
          transition: opacity ${({ theme }) => theme.transitionTiming};
        `
      : undefined};
  ${(props) =>
    props.$hide
      ? css`
          opacity: 0;
          transition: opacity ${({ theme }) => theme.transitionTiming};
        `
      : undefined};

  @media ${device.maxTablet} {
    background: transparent;
    opacity: 1;
    left: 100%;
    ${(props) =>
      props.$hide
        ? css`
            left: 100%;
            transition: left 500ms;
          `
        : undefined};
    ${(props) =>
      props.$show
        ? css`
            left: 0;
            transition: left 500ms;
          `
        : undefined};
  }
`;

const Container = styled.div<{ bgColor?: string }>`
  border-radius: 8px;
  width: 560px;
  display: flex;
  flex-direction: column;
  height: calc(100% - ${HeaderHeight}px - ${FooterHeight}px - 60px);
  max-height: 950px; // ~ x2 tickets
  margin-top: 20px;
  background-color: white;
  position: relative;

  @media ${device.maxTablet} {
    width: 100%;
    height: 100%;
    max-height: none;
    margin-top: 0;
    border-radius: 0;
  }
`;

const CloseButton = styled.button`
  display: flex;
  border: none;
  outline: none;
  cursor: pointer;
  border-radius: 50%;
  color: ${({ theme }) => theme.colors.primaryDark};
  background-color: white;
  padding: 4px;
  border: 2px solid ${({ theme }) => theme.colors.primaryDark};
  margin-right: 5px;
  -webkit-tap-highlight-color: transparent;

  &:hover {
    color: ${({ theme }) => theme.colors.primary};
    border-color: ${({ theme }) => theme.colors.primary};
  }
`;

const LeftButton = styled.button`
  position: absolute;
  display: flex;
  border: none;
  outline: none;
  cursor: pointer;
  color: ${({ theme }) => theme.colors.primaryDark};
  background-color: transparent;
  margin-left: 15px;
  -webkit-tap-highlight-color: transparent;

  &:hover {
    color: ${({ theme }) => theme.colors.primary};
  }
`;

const Header = styled.div`
  position: absolute;
  width: calc(100% - 15px);
  min-height: 54px;
  display: flex;
  align-items: center;
  justify-content: right;

  @media ${device.maxTablet} {
    position: relative;
    justify-content: left;
    width: 100%;
    border-bottom: 3px solid ${({ theme }) => theme.colors.border};
  }
`;

const Title = styled(H1)`
  position: absolute;
  width: calc(100%);
  text-align: center;
  margin: 0;
  margin-top: 5px;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;

  @media ${device.maxTablet} {
    height: calc(100% - 54px);
  }
`;

const TicketBox = styled.div`
  width: calc(100% - 30px);
`;

const List = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: auto;
  width: 100%;
  padding-top: 55px;

  @media ${device.maxTablet} {
    padding-top: 30px;
  }

  // Custom scrollbar styling
  scrollbar-color: #c1c1c1 transparent;
  scrollbar-width: 8px;
  &::-webkit-scrollbar {
    width: 12px;
  }
  &::-webkit-scrollbar-track {
    background-color: transparent;
    border-radius: 10px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #c1c1c1;
    border-radius: 10px;
    border: 2px solid transparent;
    background-clip: content-box;
  }
  &::-webkit-scrollbar-thumb:hover {
    background-color: #7d7d7d;
  }
`;

const LoaderBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  color: ${({ theme }) => theme.colors.primary};
  padding: 0 20px;

  @media ${device.maxTablet} {
    height: calc(100% - 54px);
  }
`;

type TicketModalProps = {
  tickets: CEE.Ticket[];
  teamsInfo?: POPA.TeamInfo[] | null;
  onClose: () => void;
};

const TicketModal = ({ tickets, teamsInfo, onClose }: TicketModalProps) => {
  const [qrImages, qrImagesLoading, qrImagesError] = useTicketQrImages(tickets, teamsInfo || null);
  const isTabletAndBelow = useMediaQuery({ query: device.maxTablet });
  const [hideModal, setHideModal] = React.useState(false);
  const [showModal, setShowModal] = React.useState(false);

  React.useEffect(() => {
    setTimeout(() => {
      setShowModal(true);
    }, 100);
  }, []);

  const onCloseModal = () => {
    setHideModal(true);
    setShowModal(false);
    setTimeout(() => {
      onClose();
    }, 500);
  };

  return (
    <Overlay $hide={hideModal} $show={showModal}>
      <Container>
        <Header>
          {isTabletAndBelow ? (
            <>
              <Title>Biljett</Title>
              <LeftButton onClick={() => onCloseModal()}>
                <LeftIcon width="20" height="20" />
              </LeftButton>
            </>
          ) : (
            <CloseButton onClick={() => onCloseModal()}>
              <CloseIcon size="medium" />
            </CloseButton>
          )}
        </Header>
        <Content>
          {!qrImages || qrImagesLoading || qrImagesError ? (
            <LoaderBox>
              {qrImagesError ? (
                <H2>Biljetten kunde tyvärr inte hämtas. Försök snart igen</H2>
              ) : (
                <Spinner />
              )}
            </LoaderBox>
          ) : (
            <List>
              <TicketBox>
                <Ticket tickets={tickets} teamsInfo={teamsInfo} disabled />
              </TicketBox>
              {tickets.map((ticket, index) => (
                <TicketDetails
                  key={index}
                  ticket={ticket}
                  qrImage={qrImages[ticket.EntranceCode]}
                  index={index}
                  amount={tickets.length}
                />
              ))}
            </List>
          )}
        </Content>
      </Container>
    </Overlay>
  );
};

export default TicketModal;
