import * as React from 'react';
import { darken } from 'polished';
import styled, { css } from 'styled-components';
import Spinner from './Spinner';

export enum COLOR {
  OUTLINED = 'outlined',
  PRIMARY = 'primary',
  SUCCESS = 'success',
  DANGER = 'danger',
}

const setColors = (color?: COLOR) => {
  switch (color) {
    case COLOR.SUCCESS:
      return css`
        background-color: ${({ theme }) => theme.colors.green};
        border: none;
        color: #fff;
        :hover {
          background-color: ${({ theme }) => darken(0.1, theme.colors.green)};
        }
        :active {
          background-color: ${({ theme }) => darken(0.15, theme.colors.green)};
        }
        :focus {
          background-color: ${({ theme }) => darken(0.1, theme.colors.green)};
        }
      `;
    case COLOR.DANGER:
      return css`
        background-color: ${({ theme }) => theme.colors.red};
        border: none;
        color: #fff;
        :hover {
          background-color: ${({ theme }) => darken(0.1, theme.colors.red)};
        }
        :active {
          background-color: ${({ theme }) => darken(0.15, theme.colors.red)};
        }
        :focus {
          background-color: ${({ theme }) => darken(0.1, theme.colors.red)};
        }
      `;
    case COLOR.PRIMARY:
      return css`
        border: ${({ theme }) => `1px solid ${theme.colors.primaryDark}`};
        color: ${({ theme }) => theme.colors.primaryDark};
      `;
    case COLOR.OUTLINED:
    default:
      return css`
        border: ${({ theme }) => `2px solid ${theme.colors.border}`};
        color: #000;
      `;
  }
};

const StyledButton = styled.button<{ $color?: COLOR; $fullWidth?: boolean }>`
  // default colors
  background-color: #fff;
  :hover {
    background-color: ${darken(0.1, '#fff')};
  }
  :active {
    background-color: ${darken(0.15, '#fff')};
    box-shadow: inset ${({ theme }) => theme.boxShadow};
  }
  :focus {
    background-color: ${darken(0.1, '#fff')};
  }
  ${({ $color }) => setColors($color)}

  outline: none;
  font-size: 13px;
  font-family: ${({ theme }) => theme.fonts.bold};
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: ${({ theme }) => theme.borderRadius}px;
  padding: 6px 12px;
  transition: all ${({ theme }) => theme.transitionTiming};

  min-height: 44px;
  min-width: max-content;
  width: ${({ $fullWidth }) => ($fullWidth ? '100%' : 'auto')};
  max-width: 335px;
  cursor: pointer;
  &[disabled] {
    color: #c0c0c0;
    background-color: ${({ theme }) => theme.colors.fields.backgroundDisabled};
    border-color: initial;
    cursor: default;
  }
  > * {
    :not(:last-child) {
      margin-right: 1ch;
    }
  }
`;

type StyledButtonProps = {
  color?: COLOR;
  fullWidth?: boolean;
  disabled?: boolean;
  loading?: boolean;
  onClick?: any;
};

type ButtonProps = StyledButtonProps & React.ComponentPropsWithoutRef<'button'>;

const Button = React.forwardRef(function Button(
  {
    color = COLOR.SUCCESS,
    fullWidth = true,
    children,
    loading,
    disabled,
    onClick,
    ...rest
  }: ButtonProps,
  ref: React.Ref<HTMLButtonElement>
) {
  const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    if (disabled || loading) return;
    onClick && onClick(e);
  };

  return (
    <StyledButton
      ref={ref}
      {...rest}
      $color={color}
      $fullWidth={fullWidth}
      onClick={handleClick}
      disabled={disabled}
    >
      {loading && <Spinner size="medium" />}
      {!loading && children}
    </StyledButton>
  );
});

export default Button;
