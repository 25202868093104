import * as React from 'react';
import styled from 'styled-components';
import ReactToggle, { ToggleProps } from 'react-toggle';
import './toggle.css';
import { darken } from 'polished';

const Container = styled.div`
  display: flex;
  --color-unchecked-track: ${({ theme }) => theme.colors.grey};
  --color-unchecked-track-hover: ${({ theme }) => darken(0.1, theme.colors.grey)};
  --color-checked-track: ${({ theme }) => theme.colors.green};
  --color-checked-track-hover: ${({ theme }) => darken(0.1, theme.colors.green)};
  --color-thumb-border: ${({ theme }) => theme.colors.border};
  --color-thumb-active-shadow: 'transparent';
`;

function Toggle(props: ToggleProps) {
  const { className, icons = false, ...rest } = props;

  return (
    <Container className={className}>
      <ReactToggle icons={icons} {...rest} />
    </Container>
  );
}

export default Toggle;
