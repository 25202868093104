import * as React from 'react';
import styled from 'styled-components';
import { device } from 'style/device';
import logo from './logo-w.svg';

const Image = styled.img`
  height: 28px;

  @media ${device.minTablet} {
    height: 38px;
  }
`;

export default function HeaderLogo() {
  return <Image alt="SEF Fotbollskonto" src={logo} />;
}
