import * as React from 'react';
import styled from 'styled-components';

const Container = styled.div<{ $bgColor?: string }>`
  border-radius: 8px;
  width: 95px;
  height: 95px;
  display: flex;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  background-color: ${(props) => props.$bgColor};
`;

const Image = styled.img<{ $bgColor?: string }>`
  width: 60px;
  height: 60px;
  object-fit: contain;
  position: relative;
  border: 0;
  &:after {
    content: '' attr(alt);
    position: absolute;
    border: 0;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    /* z-index: 2; */
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 22px;
    font-family: ${({ theme }) => theme.fonts.bold};
    font-weight: normal;
    background-color: ${(props) => props.$bgColor};
  }
`;

type TicketLogoProps = {
  imageUrl?: string;
  abbrv?: string;
  bgColor?: string;
};

const TicketLogo = ({ imageUrl, abbrv, bgColor }: TicketLogoProps) => {
  const [loadingImage, setLoadingImage] = React.useState(true);

  return (
    <Container $bgColor={bgColor}>
      <Image
        $bgColor={bgColor}
        src={imageUrl}
        alt={abbrv || ' '}
        onLoad={() => setLoadingImage(false)}
      />
    </Container>
  );
};

export default TicketLogo;
