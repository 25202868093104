import * as React from 'react';
import Button, { COLOR } from 'components/Button';
import styled from 'styled-components';
import CreditcardIcon from 'components/CreditcardIcon';
import I18nContext from 'I18nContext';
import PageLoader from 'components/PageLoader';
import PaymentStatus from 'components/PaymentStatus';
import { CEE } from 'api/cee/types';
import * as cee from 'api/cee';
import AppContext from 'AppContext';
import moment from 'moment';
import { device } from 'style/device';

const Card = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 4px;
  background-color: ${({ theme }) => theme.colors.background};
  border-radius: ${({ theme }) => theme.borderRadius}px;
  padding: 20px 18px;
  font-family: ${({ theme }) => theme.fonts.bold};
  color: ${({ theme }) => theme.colors.primaryDark};
  margin-bottom: 24px;
`;

const CreditcardNumber = styled.span`
  flex: 1;
`;

const StyledButton = styled(Button)`
  font-size: 11px;
`;

const List = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
`;

const Item = styled.li`
  border-radius: ${({ theme }) => theme.borderRadius}px;
  border: 2px solid ${({ theme }) => theme.colors.border};

  background-color: #fff;
  color: ${({ theme }) => theme.colors.primaryDark};
  font-family: ${({ theme }) => theme.fonts.bold};

  padding: 18px;
  margin-bottom: 14px;
`;

const OrderNumber = styled.div`
  font-size: 12px;
  width: 40%;
  padding-right: 5px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

const Date = styled.div`
  font-size: 12px;
  flex-grow: 1;
`;

const Product = styled.div`
  font-size: 14px;
  width: 40%;
  padding-right: 5px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  &:first-letter {
    text-transform: uppercase;
  }
`;

const Merchant = styled.div`
  font-size: 14px;
  flex-grow: 1;
  padding-right: 5px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  &:first-letter {
    text-transform: uppercase;
  }
`;

const Amount = styled.div`
  font-size: 13px;
  flex-grow: 0;
  white-space: nowrap;
`;

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Container = styled.main`
  display: flex;
  justify-content: center;

  @media ${device.maxTablet} {
    justify-content: left;
  }
`;

const Payments = () => {
  const { strings } = React.useContext(I18nContext);
  const { tokens } = React.useContext(AppContext);
  // const [cardInfo, setCardInfo] = React.useState<CEE.CardInfo>();
  const [orders, setOrders] = React.useState<CEE.Order[]>([]);
  const [loading, setLoading] = React.useState(true);

  React.useEffect(() => {
    if (!tokens?.accessToken) return;

    const fetchData = async () => {
      const auth = { accessToken: tokens.accessToken };
      const ordersHistory = await cee.getOrders(tokens?.PersonId, auth);

      if (ordersHistory) {
        const filteredOrdersHistory = ordersHistory
          .filter((payment) => payment.Status !== 'Initiated')
          .sort((a, b) => moment(b.CreatedAt).valueOf() - moment(a.CreatedAt).valueOf());
        setOrders(filteredOrdersHistory);
      }

      setLoading(false);
    };
    fetchData();
  }, [tokens]);

  if (loading) {
    return <PageLoader />;
  }

  if (!loading && orders?.length === 0) {
    return <Container>{strings.paymentHistoryEmpty}</Container>;
  }

  return (
    <>
      {/* Disabled for now
      {cardInfo && (
        <Card>
          <CreditcardIcon type={cardInfo.provider} />
          <CreditcardNumber>{cardInfo.number}</CreditcardNumber>
          <StyledButton fullWidth={false} color={COLOR.PRIMARY}>
            {strings.changePaymentMethod}
          </StyledButton>
        </Card>
      )}
      */}
      <List>
        {orders?.map((order, index) => (
          <Item key={index}>
            <Row style={{ marginBottom: 3 }}>
              <OrderNumber>{order.OrderNumber}</OrderNumber>
              <Date>{moment(order.CreatedAt).format('YYYY-MM-DD')}</Date>
              <PaymentStatus payment={order}></PaymentStatus>
            </Row>
            <Row>
              <Product>
                {order.Items[0]?.Product.BillingType === 'Recurring'
                  ? order.Items[0]?.Product.Name
                  : order.Items[0]?.Product.Category}
              </Product>
              <Merchant>{order.Merchant.Name}</Merchant>
              <Amount>{order.TotalAmount} kr</Amount>
            </Row>
          </Item>
        ))}
      </List>
    </>
  );
};

export default Payments;
