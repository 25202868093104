import * as React from 'react';
import styled from 'styled-components';

type StyledSvgProps = {
  width: number;
  height: number;
};
const StyledSvg = styled.svg<StyledSvgProps>`
  ${({ width }) => `
    width: ${width}px;
  `}
  ${({ height }) => `
    height: ${height}px;
  `}
`;

export type IconSizeProps = {
  size?: 'small' | 'medium' | 'big';
  width?: string;
  height?: string;
};

export const getSizeInPx = (size?: 'small' | 'medium' | 'big') => {
  return size && size === 'big' ? 74 : size && size === 'medium' ? 24 : 13;
};

export type IconProps = IconSizeProps & React.SVGAttributes<SVGElement>;
export const Icon = ({ size, width, height, ...props }: IconProps) => {
  let widthInPx = 0;
  let heightInPx = 0;

  if (!width && !height) {
    const sizeInPx = getSizeInPx(size);
    widthInPx = sizeInPx;
    heightInPx = sizeInPx;
  } else {
    widthInPx = Number(width);
    heightInPx = Number(height);
  }

  return (
    <StyledSvg
      xmlns="http://www.w3.org/2000/svg"
      fill="currentcolor"
      width={widthInPx}
      height={heightInPx}
      {...props}
    />
  );
};

export const HomeIcon = (props: IconProps) => (
  <Icon viewBox="0 0 13 13" {...props}>
    <path fill="none" d="M0,0H13V13H0Z" />
    <path
      d="M11.22,11.281a.535.535,0,0,1-.538.532H3.152a.535.535,0,0,1-.538-.532V6.489H1l5.555-5a.542.542,0,0,1,.724,0l5.555,5H11.22Z"
      transform="translate(-0.417 -0.563)"
    />
  </Icon>
);

export const MailIcon = (props: IconProps) => (
  <Icon viewBox="0 0 13 13" {...props}>
    <path fill="none" d="M0,0H13V13H0Z" />
    <path
      d="M2.533,3h9.6a.531.531,0,0,1,.533.528v8.444a.531.531,0,0,1-.533.528h-9.6A.531.531,0,0,1,2,11.972V3.528A.531.531,0,0,1,2.533,3ZM7.365,7.583,3.946,4.709l-.691.8,4.117,3.46,4.043-3.462-.7-.8L7.366,7.583Z"
      transform="translate(-0.833 -1.25)"
    />
  </Icon>
);

export const ArrowIcon = (props: IconProps) => (
  <Icon viewBox="0 0 24 24" {...props}>
    <path d="M7.41 8.59 12 13.17l4.59-4.58L18 10l-6 6-6-6z"></path>
  </Icon>
);

export const SelectArrow = (props: IconProps) => (
  <Icon viewBox="0 0 24 24" {...props}>
    <path fill="none" d="M0,0H24V24H0Z" />
    <path d="M12,14,8,10h8Z" />
  </Icon>
);

export const CloseIcon = (props: IconProps) => (
  <Icon viewBox="0 0 24 24" {...props}>
    <path fill="none" d="M0,0H24V24H0Z" />
    <path d="M12,10.586l4.95-4.95L18.364,7.05,13.414,12l4.95,4.95L16.95,18.364,12,13.414l-4.95,4.95L5.636,16.95,10.586,12,5.636,7.05,7.05,5.636Z" />
  </Icon>
);

export const LeftIcon = (props: IconProps) => (
  <Icon viewBox="0 0 16 16" {...props}>
    <path d="M16 7H3.83L9.42 1.41L8 0L0 8L8 16L9.41 14.59L3.83 9H16V7Z" />
  </Icon>
);

export const TickIcon = (props: IconProps) => (
  <Icon viewBox="0 0 24 24" {...props} stroke="currentcolor">
    <path d="M4 12L9.65685 17.6568M8.58579 17.3137L19.8995 6" strokeWidth="2" />
  </Icon>
);

export const Loading = (props: IconProps) => (
  <Icon viewBox="0 0 74 73.999" {...props}>
    <g transform="translate(0)">
      <path
        d="M157.912,83.606h-9.054a5.656,5.656,0,1,0,0,11.312h9.054a5.656,5.656,0,0,0,0-11.312Z"
        transform="translate(-89.568 -52.263)"
      />
      <path
        d="M20.366,89.262a5.656,5.656,0,0,0-5.656-5.656H5.656a5.656,5.656,0,1,0,0,11.312H14.71A5.656,5.656,0,0,0,20.366,89.262Z"
        transform="translate(0 -52.263)"
      />
      <path
        d="M88.929,143.2a5.656,5.656,0,0,0-5.656,5.656v9.054a5.656,5.656,0,0,0,11.312,0v-9.054A5.656,5.656,0,0,0,88.929,143.2Z"
        transform="translate(-52.054 -89.567)"
      />
      <path
        d="M88.929,0a5.656,5.656,0,0,0-5.656,5.656v9.053a5.656,5.656,0,0,0,11.312,0V5.657A5.656,5.656,0,0,0,88.929,0Z"
        transform="translate(-52.054 -0.001)"
      />
      <path
        d="M135.4,127.4a5.656,5.656,0,0,0-8,8l6.4,6.4a5.656,5.656,0,0,0,8-8Z"
        transform="translate(-78.694 -78.695)"
      />
      <path
        d="M34.144,26.144a5.656,5.656,0,1,0-8,8l6.4,6.4a5.656,5.656,0,0,0,8-8Z"
        transform="translate(-15.255 -15.254)"
      />
      <path
        d="M32.545,127.4l-6.4,6.4a5.656,5.656,0,0,0,8,8l6.4-6.4a5.656,5.656,0,0,0-8-8Z"
        transform="translate(-15.254 -78.694)"
      />
      <path
        d="M131.4,42.2a5.637,5.637,0,0,0,4-1.657l6.4-6.4a5.656,5.656,0,1,0-8-8l-6.4,6.4a5.656,5.656,0,0,0,4,9.656Z"
        transform="translate(-78.693 -15.253)"
      />
    </g>
  </Icon>
);

export const TicketIcon = (props: IconProps) => (
  <Icon viewBox="0 0 51 33" fill="none" {...props}>
    <path d="M5.71268 14.1737H23.3438V12.1655H5.71268V14.1737Z" fill="black" />
    <path d="M35.1632 14.3696H28.2414V12.3614H35.1632V14.3696Z" fill="black" />
    <path d="M5.71268 18.8754H21.646V16.8671H5.71268V18.8754Z" fill="black" />
    <path d="M25.9559 27.7562H21.5807V25.748H25.9559V27.7562Z" fill="black" />
    <path d="M30.6178 27.7562H34.993V25.748H30.6178V27.7562Z" fill="black" />
    <path d="M35.1215 9.6804H5.71268V7.67216H35.1215V9.6804Z" fill="black" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M51 27.5119V5.4879C51 2.71509 48.7522 0.467285 45.9794 0.467285H5.99766C3.22484 0.467285 0.977051 2.7151 0.977051 5.4879V27.5119C0.977051 30.2847 3.22486 32.5325 5.99767 32.5325H45.9794C48.7522 32.5325 51 30.2847 51 27.5119ZM48.6152 27.5119V5.4879C48.6152 4.03217 47.4351 2.85208 45.9794 2.85208H41.6402V30.1478H45.9794C47.4351 30.1478 48.6152 28.9677 48.6152 27.5119ZM39.6319 30.1478H5.99767C4.54194 30.1478 3.36184 28.9677 3.36184 27.5119V5.4879C3.36184 4.03218 4.54194 2.85208 5.99766 2.85208H39.6319V30.1478Z"
      fill="black"
    />
  </Icon>
);
