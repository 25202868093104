import * as React from 'react';
import AppContext from 'AppContext';
import { useSnackbar } from 'notistack';
import I18nContext from 'I18nContext';
import styled from 'styled-components';
import { CEE } from 'api/cee/types';
import Centered from 'components/Centered';
import * as cee from 'api/cee';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: left;
`;

const Spacing = styled.div<{ $large?: boolean }>`
  height: ${(props) => (props.$large ? 40 : 20)}px;
`;

const Link = styled.a`
  color: ${({ theme }) => theme.colors.primary};
`;

const List = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
`;

const links = [
  { id: '24380', url: 'https://onelink.to/degerforsif', displayName: 'Degerfors IF Live' },
  { id: '9396', url: 'https://onelink.to/ikbrage', displayName: 'IK Brage Live' },
  { id: '9382', url: 'https://onelink.to/gais', displayName: 'GAIS Live' },
  { id: '9400', url: 'https://onelink.to/gefleiffotboll', displayName: 'Gefle IF Live' },
  { id: '9407', url: 'https://onelink.to/gifsundsvall', displayName: 'GIF Sundsvall Live' },
  { id: '9371', url: 'https://onelink.to/hbkfotboll', displayName: 'HBK Fotboll Live' },
  { id: '9373', url: 'https://onelink.to/hifse', displayName: 'HIF Live' },
  { id: '9369', url: 'https://onelink.to/ifelfsborg', displayName: 'IF Elfsborg Live' },
  { id: '19721', url: 'https://onelink.to/bpfotboll', displayName: 'BP Live' },
  { id: '9376', url: 'https://onelink.to/ifknorrkoping', displayName: 'IFK Norrköping Live' },
  { id: '9604', url: 'https://onelink.to/ifkvarnamo', displayName: 'IFK Värnamo Live' },
  { id: '9404', url: 'https://onelink.to/siriusfotboll', displayName: 'Sirius Live' },
  { id: '9374', url: 'https://onelink.to/kalmarff', displayName: 'Kalmar FF Live' },
  { id: '9388', url: 'https://onelink.to/landskronabois', displayName: 'Landskrona BoIS Live' },
  { id: '9375', url: 'https://onelink.to/malmoff', displayName: 'MFF Live' },
  { id: '9389', url: 'https://onelink.to/maif', displayName: 'Mjällby AIF Live' },
  { id: '9455', url: 'https://onelink.to/skovdeaik', displayName: 'Skövde AIK Live' },
  { id: '9377', url: 'https://onelink.to/trelleborgsff', displayName: 'TFF Live' },
  { id: '11297', url: 'https://onelink.to/utsiktensbk', displayName: 'Utsiktens BK Live' },
  { id: '9601', url: 'https://onelink.to/varbergsboisfc', displayName: 'Varbergs BoIS Live' },
  { id: '9409', url: 'https://onelink.to/vasterassk', displayName: 'VSK Live' },
  { id: '9379', url: 'https://onelink.to/oskfotboll', displayName: 'ÖSK Live' },
  { id: '9380', url: 'https://onelink.to/oisfotboll', displayName: 'ÖIS Fotboll Live' },
  { id: '9394', url: 'https://onelink.to/ostersif', displayName: 'Östers IF Live' },
  { id: '9422', url: 'https://onelink.to/ostersundsfk', displayName: 'Östersunds FK Live' },
  { id: '19740', url: 'https://onelink.to/bkhacken', displayName: 'BK Häcken Live' },
  { id: '9370', url: 'https://onelink.to/ifkgoteborg', displayName: 'IFK Göteborg Live' },
  { id: '9368', url: 'https://onelink.to/djurgardensif', displayName: 'DIF Fotboll Live' },
  { id: '9372', url: 'https://onelink.to/hammarbyif', displayName: 'Hammarby IF Live' },
];

const filterArrayByTeamId = (arr: any) => {
  const uniqueTeamIds = new Set();
  const uniqueObjects = [];

  for (const obj of arr) {
    if (!uniqueTeamIds.has(obj.TeamId)) {
      uniqueTeamIds.add(obj.TeamId);
      uniqueObjects.push(obj);
    }
  }

  return uniqueObjects;
};

const Partner = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { strings } = React.useContext(I18nContext);
  const { tokens } = React.useContext(AppContext);
  const [linkedAccounts, setLinkedAccounts] = React.useState<CEE.LinkedAccount[] | null>(null);
  const [supporter, setSupporter] = React.useState<CEE.Supporter | null>(null);

  React.useEffect(() => {
    async function fetchData() {
      if (tokens?.accessToken) {
        try {
          const accounts = await cee.getLinkedAccounts(tokens.PersonId, {
            accessToken: tokens.accessToken,
          });
          const sup = await cee.getSupporter(tokens.PersonId, { accessToken: tokens.accessToken });

          setSupporter(sup);
          setLinkedAccounts(accounts);
        } catch (error) {
          enqueueSnackbar('Länkade konton kunde inte hämtas', { variant: 'error' });
        }
      }
    }
    fetchData();
  }, [tokens]);

  const getAppLink = (id: string) => {
    const app = links.find((l) => l.id === id);
    if (!app) return null;

    return app;
  };

  const renderAppLink = (linkedAccount: { TeamId: string; TeamName: string }) => {
    const appLink = getAppLink(linkedAccount.TeamId);

    if (!appLink) return null;

    return (
      <>
        <Link
          href={appLink.url}
          key={linkedAccount.TeamName}
          target="_blank"
          rel="noopener noreferrer"
        >
          {appLink.displayName}
        </Link>
        <Spacing />
      </>
    );
  };

  return (
    <>
      <Container>
        Hej {supporter?.GivenName}
        <Spacing />
        Varmt välkommen som samarbetspartner.
        <Spacing />
        <Centered>
          Som partner har du möjlighet att delta i events tillsammans med andra samarbetspartners
          och personer från samma partnernätverk. Du har även möjlighet att ta del av relevanta
          erbjudanden från andra partners och från klubben.
        </Centered>
        <Spacing />
        <Centered>
          Ladda ner din partnerklubbs Live-app för iOS eller Android och logga in med Fotbollskonto
          för att få tillgång till partnernätverket.
        </Centered>
        <Spacing />
        {linkedAccounts && (
          <List>{filterArrayByTeamId(linkedAccounts)?.map((a: any) => renderAppLink(a))}</List>
        )}
      </Container>
    </>
  );
};

export default Partner;
