import * as React from 'react';
import styled from 'styled-components';
import { Height as HeaderHeight } from 'components/Header';
import { Height as FooterHeight } from 'components/Footer';
import Divider from 'components/Divider';
import { H1 } from 'style/text';
import { device } from 'style/device';
import { useMediaQuery } from 'react-responsive';

const Main = styled.main`
  max-width: 435px;
  margin-top: ${HeaderHeight + 50}px;
  margin-bottom: ${FooterHeight + 50}px;
  margin-left: auto;
  margin-right: auto;
  padding-top: 0px;
  padding-bottom: 32px;
  padding-left: 10px;
  padding-right: 10px;
  box-shadow: none;
  border-radius: 8px;

  @media ${device.minTablet} {
    max-width: 580px;
    padding-top: 30px;
    padding-left: 32px;
    padding-right: 32px;
    box-shadow: 0px 15px 10px 0px rgba(0, 0, 0, 0.1), 0px 6px 4px 0px rgba(0, 0, 0, 0.05);
  }
`;

type Props = React.PropsWithChildren<{
  title?: string;
}>;

function Page({ title, children }: Props) {
  const isTabOrBigger = useMediaQuery({ query: device.minTablet });
  return (
    <Main>
      {title && (
        <>
          <H1>{title}</H1>
          {isTabOrBigger && <Divider style={{ marginTop: 20 }} />}
        </>
      )}
      {children}
    </Main>
  );
}

export default Page;
