/**
 * Returns an object with all URL parameters.
 */
export function getUrlParams(url?: string): Record<string, string> {
  const urlObj = url ? new URL(url) : window.location;
  const params: Record<string, string> = {};

  urlObj.search
    .slice(1)
    .split('&')
    .forEach((entry: string) => {
      const tuple = entry.split('=', 2);
      const name = decodeURIComponent(tuple[0]);

      params[name] = tuple.length > 1 ? decodeURIComponent(tuple[1]) : '';
    });

  return params;
}

export function toDateString(date?: Date | string | null): string {
  if (!date) return '';

  const dt = typeof date === 'string' ? new Date(date) : date;
  return dt.toLocaleDateString('sv', {
    day: 'numeric',
    month: 'numeric',
    year: 'numeric',
  });
}

export function addDays(date: Date | string, days: number): Date {
  const dt = typeof date === 'string' ? new Date(date) : date;
  dt.setDate(dt.getDate() + days);
  return dt;
}

export function subtractDays(date: Date | string, days: number): Date {
  const dt = typeof date === 'string' ? new Date(date) : date;
  dt.setDate(dt.getDate() - days);
  return dt;
}

function wait(ms: number) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export async function putWithErrorHandling(
  url: string,
  headers: any,
  data: any,
  handleError: Function,
  retries = 4,
  interval = 3000,
  etag?: string
): Promise<Response> {
  // Make PUT request with provided data and etag
  const response = await fetch(url, {
    method: 'PUT',
    headers: headers,
    body: JSON.stringify(etag ? { ...data, Etag: etag } : { ...data }),
  });

  if (response.status === 400) {
    if (retries > 0) {
      await wait(interval);
      const d = await handleError();
      const newEtag = d.Etag;
      return await putWithErrorHandling(
        url,
        headers,
        data,
        handleError,
        retries - 1,
        interval,
        newEtag
      );
    } else {
      throw new Error(`Failed to perform PUT request. Status: ${response.status}`);
    }
  }

  return response;
}
