import React, { useState } from 'react';
import styled from 'styled-components';
import { ArrowIcon } from 'components/Icon';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  border: 2px solid #eaeaea;
  border-radius: ${({ theme }) => theme.borderRadius}px;
  &:not(:last-child) {
    margin-bottom: 16px;
  }
`;

const Header = styled.div`
  display: flex;
  flex-grow: 1;
  justify-content: center;
  align-items: center;
  height: 72px;
  cursor: pointer;
  padding-left: 25px;
  padding-right: 15px;
`;

const Title = styled.div`
  display: flex;
  flex-grow: 1;
  font-family: ${({ theme }) => theme.fonts.bold};
  color: ${({ theme }) => theme.colors.primaryDark};
  padding-right: 25px;
`;

const Expand = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  color: ${({ theme }) => theme.colors.primaryDark};
`;

const Content = styled.div`
  padding: 10px;
`;

type Props = React.PropsWithChildren<{
  title: React.ReactNode;
}>;

const Accordion = ({ title, children }: Props) => {
  const [expanded, setExpanded] = useState(false);

  const onClick = () => {
    setExpanded(!expanded);
  };

  return (
    <Container>
      <Header onClick={onClick}>
        <Title>{title}</Title>
        <Expand>
          <ArrowIcon
            size="medium"
            style={expanded ? { transform: 'rotate(180deg)' } : { transform: 'rotate(0deg)' }}
          />
        </Expand>
      </Header>
      {expanded && <Content>{children}</Content>}
    </Container>
  );
};

export default Accordion;
