import { MyPageError } from 'MyPageError';
import { POPA } from './types';

const { REACT_APP_AUTH_BASE_URL, REACT_APP_POPA_BACKEND_BASE_URL } = process.env;

const CLIENT = 'mypage';
const PLATFORM = 'web';

export function getRedirectToLoginUrl(returnUrl: string): string {
  const frontendCallback = encodeURIComponent(returnUrl);
  return `${REACT_APP_AUTH_BASE_URL}/login?client=${CLIENT}&platform=${PLATFORM}&frontendCallback=${frontendCallback}`;
}

export async function getRedirectToLogoutUrl(idToken: string, returnUrl: string) {
  const frontendCallback = encodeURIComponent(returnUrl);
  const res = await fetch(`${REACT_APP_AUTH_BASE_URL}/logout`, {
    method: 'POST',
    body: JSON.stringify({
      idToken,
      frontendCallback,
    }),
  });

  if (!res.ok) {
    throw new MyPageError(res.status, 'Failed to logout');
  }

  const data = await res.json();
  return data.logoutUrl;
}

export async function getTokensFromTempToken(
  code: string
): Promise<POPA.TokensResponseData | null> {
  const res = await fetch(`${REACT_APP_AUTH_BASE_URL}/tokens`, {
    method: 'POST',
    body: JSON.stringify({
      client: CLIENT,
      platform: PLATFORM,
      code,
    }),
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  });

  const data = await res.json();

  if (!res.ok) {
    throw new MyPageError(res.status, data.error);
  }

  return data;
}

export async function getTeamInfo(esId?: string): Promise<POPA.TeamInfo[] | null> {
  const url = esId
    ? `${REACT_APP_POPA_BACKEND_BASE_URL}/teams?esid=${esId}`
    : `${REACT_APP_POPA_BACKEND_BASE_URL}/teams`;
  const res = await fetch(url);

  if (!res.ok) {
    throw new MyPageError(res.status, 'Failed to fetch team info');
  }

  const data = await res.json();
  return [...data];
}
