import './font.css';
import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
  body {
    margin: 0;
    padding: 0;
    font-size: 14px;
    font-family: 'Gilroy-Regular', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    a {
      color: currentColor;
      text-decoration: none;
      cursor: pointer;
    }
  }
`;

export default GlobalStyle;
