import * as React from 'react';
import AppContext from 'AppContext';
import { useQuery } from '@tanstack/react-query';
import { getQrImage } from 'api/cee';
import { CEE } from 'api/cee/types';
import { POPA } from 'api/popa/types';

export const useTicketQrImages = (tickets: CEE.Ticket[], teamsInfo: POPA.TeamInfo[] | null) => {
  const [mainTicket] = tickets;
  const ticketTeamId = mainTicket.TeamId;
  const teamInfo = teamsInfo?.find(({ everySportId }) => everySportId === Number(ticketTeamId));

  const { tokens } = React.useContext(AppContext);
  const entraceCodes = tickets.map((ticket) => ticket.EntranceCode);
  const queryKey = ['ticket-qr-images', entraceCodes];

  const ticketType = teamInfo?.ticketType || 'code128';

  const { isLoading, error, data } = useQuery({
    queryKey,
    queryFn: async () => {
      if (!tokens?.accessToken) return null;
      const auth = { accessToken: tokens.accessToken };

      let mappedQrImages: Record<string, string> = {};
      const qrImages = entraceCodes.map(async (entranceCode) => {
        const qrImage = await getQrImage(entranceCode, ticketType, auth);
        mappedQrImages[entranceCode] = qrImage;
        return qrImage;
      });
      await Promise.all(qrImages);
      return mappedQrImages;
    },
  });

  return [data, isLoading, error] as [Record<string, string> | null, boolean, Error | null];
};
