import * as React from 'react';
import moment from 'moment';
import styled from 'styled-components';
import { useSnackbar } from 'notistack';
import * as cee from 'api/cee';
import { CEE } from 'api/cee/types';
import AppContext from 'AppContext';
import I18nContext from 'I18nContext';
import Button, { COLOR } from 'components/Button';
import PageLoader from 'components/PageLoader';
import Select, { Option } from 'components/Select';
import Centered from 'components/Centered';
import { toDateString } from 'utils';
import { RouteComponentProps } from 'react-router-dom';
import { device } from 'style/device';
import { logError, logInfo } from 'utils/logger';
import { MyPageError } from 'MyPageError';

const Link = styled.a`
  color: ${({ theme }) => theme.colors.primary};
`;

const Spacing = styled.div<{ $large?: boolean }>`
  height: ${(props) => (props.$large ? 40 : 20)}px;
`;

const Disclaimer = styled.p`
  font-size: 12px;
  > a {
    text-decoration: underline;
  }
`;

const DisclaimerCentered = styled(Disclaimer)`
  @media (min-width: 768px) {
    text-align: center;
  }
`;

const Form = styled.form`
  width: 100%;
  flex: 1;
`;

const FormItem = styled.div`
  margin-bottom: 22px;
  &:last-child {
    margin-bottom: 0;
  }
`;

const FormGrid = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;

  > label {
    margin-bottom: 3px;
    font-size: 12px;
  }

  @media ${device.minTablet} {
    gap: 18px;
    > label {
      display: flex;
      align-items: center;
    }
  }

  @media ${device.maxTablet} {
    grid-template-columns: 1fr;
    > input {
      margin-bottom: 14px;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
`;

const Label = styled.label`
  display: inline-block;
  font-family: ${({ theme }) => theme.fonts.bold};
  color: ${({ theme }) => theme.colors.primaryDark};
  margin-bottom: 7px;
`;

const Input = styled.input<{ $isEmptyOverride?: boolean }>`
  color: ${({ theme }) => theme.colors.fields.text};
  outline: none;
  box-sizing: border-box;
  font-family: ${({ theme }) => theme.fonts.regular};
  font-size: 13px;
  width: 100%;
  height: 46px;
  background-color: ${({ theme, $isEmptyOverride }) =>
    $isEmptyOverride ? 'transparent' : theme.colors.fields.background};
  border: 1px solid ${({ theme }) => theme.colors.fields.border};
  border-radius: ${({ theme }) => theme.borderRadius}px;
  padding: 0 20px;
  transition: border-color ${({ theme }) => theme.transitionTiming};

  &:placeholder-shown {
    background-color: transparent;
  }
  &:hover {
    border-color: ${({ theme }) => theme.colors.primary};
  }
  &:focus-within {
    border: 2px solid ${({ theme }) => theme.colors.primary};
    padding: 0 19px;
  }
  &:disabled {
    background-color: ${({ theme }) => theme.colors.fields.backgroundDisabled};
    border-color: ${({ theme }) => theme.colors.fields.border};
    color: ${({ theme }) => theme.colors.fields.textDisabled};
    opacity: 1; // Disable iOS Safari opacity
  }
  // Normalize styling of date input (fixes iOS Safari layout issues)
  &[type='date'] {
    -webkit-appearance: none;
    -moz-appearance: none;
  }
`;

type FormValues = {
  givenName?: string;
  surname?: string;
  cellPhone?: string;
  birthday?: string;
};

const toFormState = (supporter: CEE.Supporter) => ({
  givenName: supporter.GivenName,
  surname: supporter.Surname,
  cellPhone: supporter?.CellPhone,
  birthday: toDateString(supporter?.Birthday),
});

type TeamOptions = {
  allsvenskan: Option[];
  superettan: Option[];
};

/**
 * For some reason putting this in CEE namespace doesn't work
 * Probably some typescript config..
 */
enum League {
  Allsvenskan = 1,
  Superettan = 2,
}

const toOption = (team: CEE.Team): Option => ({
  value: team.Id,
  label: team.DisplayName,
});

const AccountInfo = (props: RouteComponentProps<any>) => {
  const { tokens } = React.useContext(AppContext);
  const { strings } = React.useContext(I18nContext);
  const { enqueueSnackbar } = useSnackbar();

  const [formState, setFormState] = React.useState<FormValues>({});
  const [saving, setSaving] = React.useState(false);
  const [supporterLoading, setSupporterLoading] = React.useState(true);
  // This is only saved in order to add the required api fields on save.
  const [supporter, setSupporter] = React.useState<CEE.Supporter | null>(null);

  const [selectedTeam, setSelectedTeam] = React.useState<Option | null>(null);
  const [teamsLoading, setTeamsLoading] = React.useState(true);
  const [teamOptions, setTeamOptions] = React.useState<TeamOptions>({
    allsvenskan: [],
    superettan: [],
  });
  const [linkedAccounts, setLinkedAccounts] = React.useState<CEE.LinkedAccount[] | null>(null);

  React.useEffect(() => {
    async function fetchData() {
      if (tokens?.accessToken) {
        const sup = await cee.getSupporter(tokens.PersonId, { accessToken: tokens.accessToken });
        const teams = await cee.getTeams({ accessToken: tokens.accessToken });
        const allsvenskan = teams
          .filter((team) => team.League === League.Allsvenskan)
          .sort((a, b) => a.DisplayName.localeCompare(b.DisplayName))
          .map(toOption);
        const superettan = teams
          .filter((team) => team.League === League.Superettan)
          .sort((a, b) => a.DisplayName.localeCompare(b.DisplayName))
          .map(toOption);
        const favoriteTeam =
          [...allsvenskan, ...superettan].find((t) => t.value === sup.SupportedTeams[0]?.Id) ||
          null;
        try {
          const accounts = await cee.getLinkedAccounts(tokens.PersonId, {
            accessToken: tokens.accessToken,
          });
          setLinkedAccounts(accounts);
        } catch (error) {
          enqueueSnackbar('Länkade konton kunde inte hämtas', { variant: 'error' });
        }
        setTeamOptions({ allsvenskan, superettan });
        setTeamsLoading(false);
        setSelectedTeam(favoriteTeam);

        setSupporter(sup);
        setFormState(toFormState(sup));
        setSupporterLoading(false);
      }
    }
    fetchData();
  }, [tokens]);

  const onChange = (e: any) => {
    setFormState({ ...formState, [e.target.name]: e.target.value });
  };

  const onSave = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    if (saving || !tokens?.accessToken || !supporter) return;

    setSaving(true);

    if (
      formState?.birthday !== '' &&
      !moment(moment(formState.birthday)).isBetween('1900-01-01', moment())
    ) {
      const endDate = moment().format('YYYY-MM-DD');
      enqueueSnackbar(strings.dateError + endDate, {
        variant: 'error',
      });
      setSaving(false);
      return;
    }

    const toUpdate: CEE.Supporter = {
      ...supporter,
      GivenName: formState.givenName,
      Surname: formState.surname,
      CellPhone: formState.cellPhone?.replace(/\D/g, ''),
      Birthday: formState.birthday,
      SupportedTeams: selectedTeam
        ? [{ Id: selectedTeam.value, DisplayName: selectedTeam.label }]
        : [],
    };

    try {
      const saved = await cee.updateSupporter(tokens.PersonId, toUpdate, {
        accessToken: tokens.accessToken,
      });
      setSupporter(saved);
      setFormState(toFormState(saved));
      enqueueSnackbar(strings.savedSuccess, { variant: 'success' });
      logInfo('AccountInfo - Updated user successfully', {
        personId: toUpdate.Id,
      });
    } catch (error) {
      const myPageError = error as MyPageError;
      logError('AccountInfo - Failed updating user', {
        personId: toUpdate.Id,
        errorMessage: myPageError,
      });
      enqueueSnackbar(strings.savedError, { variant: 'error' });
    }

    setSaving(false);
  };

  if (supporterLoading) {
    return <PageLoader />;
  }

  return (
    <>
      <Form>
        <FormItem>
          <Label htmlFor="email">{strings.userEmailAndAccount}</Label>
          <Input id="email" name="email" type="text" value={supporter?.Email} disabled />
        </FormItem>
        {linkedAccounts && linkedAccounts.length > 0 && (
          <>
            <Label htmlFor="linkedAccounts">{strings.linkedAccounts}</Label>
            <FormGrid>
              {linkedAccounts?.map((account) => (
                <React.Fragment key={`linkedAccount-${account.OrganizationId}`}>
                  <Label
                    key={`linkedAccountLabel-${account.OrganizationId}`}
                    htmlFor={`linkedAccountEmail-${account.Email}`}
                  >
                    {account.TeamName}
                  </Label>
                  <Input
                    key={`linkedAccountInput-${account.OrganizationId}`}
                    id={`linkedAccountEmail-${account.Email}`}
                    name="linkedEmail"
                    type="text"
                    value={account.Email}
                    disabled
                  />
                </React.Fragment>
              ))}
            </FormGrid>
            <Disclaimer>{strings.linkedAccountsDisclaimer}</Disclaimer>
            <Spacing />
          </>
        )}
        <FormItem>
          <Label htmlFor="givenName">{strings.givenName}</Label>
          <Input
            id="givenName"
            name="givenName"
            type="text"
            value={formState?.givenName}
            onChange={onChange}
            placeholder=" "
          />
        </FormItem>
        <FormItem>
          <Label htmlFor="surname">{strings.surname}</Label>
          <Input
            id="surname"
            name="surname"
            type="text"
            value={formState?.surname}
            onChange={onChange}
            placeholder=" "
          />
        </FormItem>
        <FormItem>
          <Label htmlFor="cellPhone">{strings.cellphone}</Label>
          <Input
            id="cellPhone"
            name="cellPhone"
            type="tel"
            value={formState?.cellPhone}
            onChange={onChange}
            pattern="[0-9]+"
            placeholder=" "
          />
        </FormItem>
        <FormItem>
          <Label htmlFor="birthday">{strings.birthday}</Label>
          {/*
              This div is here to force the date input to be on a separate line
              Simply adding `display: block` to the date input screws up the styling in safari
          */}
          <div>
            <Input
              $isEmptyOverride={!formState?.birthday}
              id="birthday"
              name="birthday"
              type="date"
              min="1900-01-01"
              max={moment().format('YYYY-MM-DD')}
              value={formState?.birthday}
              onChange={onChange}
              placeholder=""
            />
          </div>
        </FormItem>
        <FormItem>
          <Label htmlFor="favoriteTeam">{strings.favTeam}</Label>
          <Select
            isClearable
            isSearchable={false}
            value={selectedTeam}
            name="favoriteTeam"
            inputId="favoriteTeam"
            isLoading={teamsLoading}
            placeholder={teamsLoading ? strings.loading : strings.selectTeam}
            onChange={(option) => setSelectedTeam(option as Option | null)}
            options={[
              {
                label: strings.allsvenskan,
                options: teamOptions.allsvenskan,
              },
              {
                label: strings.superettan,
                options: teamOptions.superettan,
              },
            ]}
          />
          <Disclaimer>
            {strings.favTeamDisclamer1}{' '}
            <Link href={strings.teamsPrivacyPolicyUrl} target="_blank" rel="noopener noreferrer">
              {strings.favTeamPrivacyPolicy}
            </Link>{' '}
            {strings.favTeamDisclamer2}
          </Disclaimer>
        </FormItem>
        <Spacing />
        <Centered>
          <Button onClick={onSave} loading={saving}>
            {strings.save}
          </Button>
        </Centered>
        <Spacing />
        <DisclaimerCentered>
          <>
            {strings.accountHelpText}{' '}
            <Link href={`mailto: ${strings.supportEmail}`}>{strings.supportEmail}</Link>
          </>
        </DisclaimerCentered>
      </Form>
    </>
  );
};

export default AccountInfo;
