import React, { useState } from 'react';
import { CEE } from 'api/cee/types';
import styled, { css } from 'styled-components';
import I18nContext from 'I18nContext';
import { toDateString } from 'utils';
import Button, { COLOR } from 'components/Button';
import ClubSubscriptionModal from './ClubSubscriptionModal';
import { useCollection } from 'hooks/firebase';
import { POPA } from 'api/popa/types';

const {
  REACT_APP_CHECKOUT_CLUB_SUBSCRIPTION_URL,
  REACT_APP_CHECKOUT_HIGHLIGHTS_PLUS_URL,
  REACT_APP_ACCOUNT_INFO_URL,
  REACT_APP_HIGHTLIGHTS_PLUS_V3_ID,
} = process.env as Record<string, string>;

const Card = styled.div`
  background-color: ${({ theme }) => theme.colors.background};
  border-radius: ${({ theme }) => theme.borderRadius}px;
  padding: 10px 18px;
  font-family: ${({ theme }) => theme.fonts.bold};
  color: ${({ theme }) => theme.colors.primaryDark};
  margin-bottom: 16px;
`;

const Row = styled.div<{ $big?: boolean; $bottomline?: boolean }>`
  display: flex;
  align-items: center;
  gap: 14px;
  padding: 16px 0;
  font-size: ${({ $big }) => ($big ? 14 : 12)}px;

  ${(props) =>
    props.$bottomline
      ? css`
          border-bottom: 2px solid #fff;
        `
      : undefined};
`;

const Detail = styled.div`
  display: flex;
  flex-grow: 1;
  justify-content: space-between;
  align-items: center;
`;

interface Props {
  product: CEE.PersonProductV3;
  onActivate: (checkoutUrl: string) => void;
  onChangePayment: (checkoutUrl: string) => void;
  onReadMore: (readMoreUrl: string) => void;
  onCancelSubscription: () => void;
}

const ClubSubscriptionCard = ({
  product,
  onActivate,
  onChangePayment,
  onReadMore,
  onCancelSubscription,
}: Props) => {
  const { strings } = React.useContext(I18nContext);
  const [showCancelModal, setShowCancelModal] = useState(false);
  const merchantId = product.Product.MerchantId;
  const [checkoutTeams, checkoutTeamsLoading, checkoutTeamsError] =
    useCollection<POPA.CheckoutTeam>(['checkoutTeams', merchantId], `/checkout/fotbollplay/teams`, {
      filter: {
        field: 'merchantId',
        operator: '==',
        value: merchantId,
      },
    });

  const checkoutTeam = checkoutTeams?.[0];
  const buttonDisabled =
    checkoutTeamsLoading ||
    !!checkoutTeamsError ||
    !checkoutTeam ||
    !checkoutTeam?.merchantId ||
    !checkoutTeam?.teamId;

  const isHighlightsPlus =
    product.Product.Id.toUpperCase() === REACT_APP_HIGHTLIGHTS_PLUS_V3_ID.toUpperCase();

  function isActive(product: CEE.PersonProductV3) {
    return product.Status === 'Active';
  }

  function isCancelled(product: CEE.PersonProductV3) {
    return product.Status === 'Cancelled';
  }

  function getStatus(product: CEE.PersonProductV3) {
    switch (product.Status) {
      case 'Active':
        return strings.active;
      case 'Cancelled':
        return strings.cancelled;
      case 'Inactive':
        return strings.inactive;
      default:
        return '';
    }
  }

  const getUrl = (teamId: string) => {
    const url = new URL(REACT_APP_CHECKOUT_CLUB_SUBSCRIPTION_URL.replace('{teamId}', teamId));
    url.searchParams.set('productid', product.Product.Id);
    return url.toString();
  };

  const onModalChangePayment = () => {
    if (isHighlightsPlus) {
      onChangePayment(REACT_APP_CHECKOUT_HIGHLIGHTS_PLUS_URL);
    } else if (checkoutTeam?.teamId) {
      onChangePayment(getUrl(checkoutTeam.teamId));
    }
  };

  const onModalActivate = () => {
    if (isHighlightsPlus) {
      onActivate(REACT_APP_CHECKOUT_HIGHLIGHTS_PLUS_URL);
    } else if (checkoutTeam?.teamId) {
      onActivate(getUrl(checkoutTeam.teamId));
    }
  };

  const onShowCancelModal = () => {
    setShowCancelModal(true);
  };

  const onModalCancel = () => {
    setShowCancelModal(false);
  };

  const onModalDone = () => {
    setShowCancelModal(false);
    onCancelSubscription();
  };

  return (
    <>
      {showCancelModal && (
        <ClubSubscriptionModal product={product} onCancel={onModalCancel} onDone={onModalDone} />
      )}
      <Card>
        <Row $bottomline style={{ display: 'block' }}>
          <Detail>
            <span>{strings.status}</span>
            <span>{getStatus(product)}</span>
          </Detail>
          {product.SubscriptionStartDate && (
            <Detail>
              <span>{strings.started}</span>
              <span>{toDateString(product.SubscriptionStartDate)}</span>
            </Detail>
          )}
          {isActive(product) && product.SubscriptionNextRenewalDate && (
            <Detail>
              <span>{strings.nextPayment}</span>
              <div>{toDateString(product.SubscriptionNextRenewalDate)}</div>
            </Detail>
          )}
          {isCancelled(product) && (
            <>
              {product.SubscriptionCancelledAt && (
                <Detail>
                  <span>{strings.cancelledAt}</span>
                  <span>{toDateString(product.SubscriptionCancelledAt)}</span>
                </Detail>
              )}
              {product.SubscriptionNextRenewalDate && (
                <Detail>
                  <span>{strings.validUntil}</span>
                  <span>{toDateString(product.SubscriptionNextRenewalDate)}</span>
                </Detail>
              )}
            </>
          )}
        </Row>
        <Row style={{ flexDirection: 'column' }}>
          {isActive(product) && (
            <>
              <Button
                onClick={onModalChangePayment}
                color={COLOR.PRIMARY}
                disabled={buttonDisabled}
              >
                {strings.changePayment}
              </Button>
              <Button onClick={onShowCancelModal} color={COLOR.DANGER}>
                {strings.cancel}
              </Button>
            </>
          )}
          {!isActive(product) && (
            <>
              <Button onClick={onModalActivate} disabled={buttonDisabled}>
                {strings.buy}
              </Button>
              <Button color={COLOR.PRIMARY} onClick={() => onReadMore(REACT_APP_ACCOUNT_INFO_URL)}>
                {strings.readMore}
              </Button>
            </>
          )}
        </Row>
      </Card>
    </>
  );
};

export default ClubSubscriptionCard;
