import React from 'react';
import { getSizeInPx, IconProps, Loading as Icon } from './Icon';
import styled from 'styled-components';

const Spinning = styled.div`
  display: inline-block;
  margin: 0;
  padding: 0;
  animation-name: spin;
  animation-duration: 2500ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;

  @keyframes spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
`;

function Spinner({ size = 'big', ...rest }: IconProps) {
  return (
    <Spinning style={{ height: getSizeInPx(size), width: getSizeInPx(size) }}>
      <Icon size={size} {...rest} />
    </Spinning>
  );
}

export default Spinner;
