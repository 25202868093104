import styled from 'styled-components';
import { device } from './device';

export const H1 = styled.h1`
  font-size: 22px;
  line-height: 31px;
  font-family: ${({ theme }) => theme.fonts.bold};
  font-weight: normal;
  color: ${({ theme }) => theme.colors.primary};
  margin: 0 0 24px 0;

  @media ${device.minTablet} {
    font-size: 30px;
    text-align: center;
  }
`;

export const H2 = styled.h2`
  font-size: 16px;
  line-height: 23px;
  font-family: ${({ theme }) => theme.fonts.bold};
  font-weight: normal;
  color: ${({ theme }) => theme.colors.primary};
  margin: 0;

  @media ${device.minTablet} {
    font-size: 19px;
    line-height: 28px;
    margin-bottom: 24px;
  }
`;
