import { CEE } from './types';
import { MyPageError } from 'MyPageError';

const { REACT_APP_CEE_BASE_URL } = process.env;

function getHeaders(accessToken: string): Record<string, string> {
  return {
    Authorization: `Bearer ${accessToken}`,
    Accept: 'application/json',
    'Content-Type': 'application/json',
  };
}

export async function getProduct(id: string, accessToken: string): Promise<CEE.ProductV3> {
  const url = `${REACT_APP_CEE_BASE_URL}/api/billing/v3/products/${id}`;
  const res = await fetch(url, {
    method: 'GET',
    headers: getHeaders(accessToken),
  });

  if (!res.ok) {
    throw new MyPageError(res.status, `Failed to fetch product ${id} from server`);
  }

  const products = await res.json();
  return products as CEE.ProductV3;
}

export async function getPersonSubscriptionProducts(
  personId: string,
  accessToken: string
): Promise<CEE.PersonProductV3[]> {
  const url = `${REACT_APP_CEE_BASE_URL}/api/billing/v3/persons/${personId}/products?offset=0&productType=Recurring`;
  const res = await fetch(url, {
    method: 'GET',
    headers: getHeaders(accessToken),
  });

  if (!res.ok) {
    throw new MyPageError(res.status, `Failed to fetch person products from server`);
  }

  const products = await res.json();
  return products.Data as CEE.PersonProductV3[];
}

export async function cancelPersonSubscriptionProduct(
  personId: string,
  subscriptionId: string,
  accessToken: string
) {
  const url = `${REACT_APP_CEE_BASE_URL}/api/billing/v3/persons/${personId}/subscriptionproductcancellation/${subscriptionId}`;
  const res = await fetch(url, {
    method: 'POST',
    headers: getHeaders(accessToken),
    body: JSON.stringify({
      Source: 'MyPage',
      CancellationReason: 'Cancelled by User',
    }),
  });

  if (!res.ok) {
    throw new MyPageError(res.status, 'Failed to cancel subscription on server');
  }
}
