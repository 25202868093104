import * as React from 'react';
import AppContext from 'AppContext';
import PageLoader from 'components/PageLoader';
import I18nContext from 'I18nContext';
import styled from 'styled-components';
import { CEE } from 'api/cee/types';
import { POPA } from 'api/popa/types';
import * as cee from 'api/cee';
import * as popa from 'api/popa';
import moment from 'moment';
import Ticket from 'components/Tickets/Ticket';
import TicketModal from 'components/Tickets/TicketModal';
import ticket from 'components/Tickets/ticket.svg';

const createTicketsModel = (tickets: CEE.Ticket[]) => {
  const seasonTicketIds: Record<string, boolean> = {};
  const ticketsModel = tickets.filter((ticket) => {
    if (ticket.TicketType === 'season') {
      if (!seasonTicketIds[ticket.TicketId]) {
        seasonTicketIds[ticket.TicketId] = true;
        return true;
      }
      return false;
    }
    return true;
  });

  return ticketsModel;
};

function groupByEventId(tickets: CEE.Ticket[]) {
  return tickets?.reduce((acc, currentTicket) => {
    const el = acc.find((r) => currentTicket.Event.Id === r[0].Event.Id);
    if (el) {
      el.push(currentTicket);
    } else {
      acc.push([currentTicket]);
    }
    return acc;
  }, [] as CEE.Ticket[][]);
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 30px;
`;

const Image = styled.img`
  height: 90px;
  margin-top: 15px;
  margin-bottom: 35px;
`;

const Tickets = () => {
  const { strings } = React.useContext(I18nContext);
  const { tokens } = React.useContext(AppContext);
  const [groupedTickets, setGroupedTickets] = React.useState<CEE.Ticket[][]>([]);
  const [teamsInfo, setTeamsInfo] = React.useState<POPA.TeamInfo[] | null>(null);
  const [loadingTickets, setLoadingTickets] = React.useState(true);
  const [loadingTeamsInfo, setLoadingTeamsInfo] = React.useState(true);
  const [showTickets, setShowTickets] = React.useState<CEE.Ticket[] | null>(null);

  React.useEffect(() => {
    if (!tokens?.accessToken) return;

    const fetchTickets = async () => {
      const auth = { accessToken: tokens.accessToken };
      const fromDate = moment().subtract(1, 'days').format('YYYY-MM-DD');
      const tickets = await cee.getTickets(tokens?.PersonId, fromDate, auth);
      const ticketsModel = createTicketsModel(tickets);
      const groupedByEventId = groupByEventId(ticketsModel);
      setGroupedTickets(groupedByEventId);
      setLoadingTickets(false);
    };

    fetchTickets();
  }, [tokens]);

  React.useEffect(() => {
    if (loadingTickets || teamsInfo) return;

    const fetchTeamsInfo = async () => {
      const newTeamsInfo = await popa.getTeamInfo();
      setTeamsInfo(newTeamsInfo);
      setLoadingTeamsInfo(false);
    };

    if (groupedTickets.length > 0) {
      // Only fetch teams info if there are tickets
      fetchTeamsInfo();
    } else {
      setLoadingTeamsInfo(false);
    }
  });

  if (loadingTickets || loadingTeamsInfo) {
    return <PageLoader />;
  }

  if (!loadingTickets && groupedTickets.length === 0) {
    return (
      <Container>
        <div>{strings.ticketsEmpty}</div>
        <Image alt="Ticket" src={ticket} />
      </Container>
    );
  }

  return (
    <>
      <Container>
        {groupedTickets.map((tickets, index) => (
          <Ticket
            key={index}
            tickets={tickets}
            teamsInfo={teamsInfo}
            onClick={(tickets) => setShowTickets(tickets)}
          />
        ))}
      </Container>
      {showTickets && (
        <TicketModal
          tickets={showTickets}
          teamsInfo={teamsInfo}
          onClose={() => setShowTickets(null)}
        />
      )}
    </>
  );
};

export default Tickets;
