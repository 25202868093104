/**
 * CEE API.
 * http://supplier.sef-platform.info/wiki/Overview_and_Integration_with_CEE
 *
 * Documentation:
 * https://tst-api-services-v2.azurewebsites.net/swagger/ui/index#/
 */

import { putWithErrorHandling } from 'utils';
import { CEE } from './types';
import { MyPageError } from 'MyPageError';

const { REACT_APP_CEE_BASE_URL } = process.env;
type AuthOptions = { accessToken: string };

function getHeaders(auth: AuthOptions): Record<string, string> {
  return {
    Authorization: `Bearer ${auth.accessToken}`,
    Accept: 'application/json',
    'Content-Type': 'application/json',
  };
}

export async function getUser(auth: AuthOptions): Promise<CEE.User> {
  const url = `${REACT_APP_CEE_BASE_URL}/api/v2/Authentication/User/CeePersonId`;
  const res = await fetch(url, {
    method: 'GET',
    headers: getHeaders(auth),
  });

  if (!res.ok) {
    throw new MyPageError(res.status, 'Failed to fetch user from server', url);
  }

  const user = (await res.json()) as CEE.User;
  return user;
}

// Using temporary mocked endpoint
export async function getLinkedAccounts(
  personId: string,
  auth: AuthOptions
): Promise<CEE.LinkedAccount[]> {
  const url = `${REACT_APP_CEE_BASE_URL}/api/v2/Persons/${personId}/LinkedAccounts`;
  const res = await fetch(url, {
    method: 'GET',
    headers: getHeaders(auth),
  });

  if (!res.ok) {
    throw new MyPageError(res.status, 'Failed to fetch linked accounts from server', url);
  }
  const linkedAccounts = await res.json();
  return linkedAccounts as CEE.LinkedAccount[];
}

/**
 * CEE.Supporter has fields called EmailConsent, SmsConsent and PushConsent
 * although not optional they are not to be trusted.
 * !They are always false because the boolean default is false.
 * I think they are only set for some Landing page
 * where a user can anonymously updatate their consents.
 */
export async function getSupporter(personId: string, auth: AuthOptions): Promise<CEE.Supporter> {
  const url = `${REACT_APP_CEE_BASE_URL}/api/v2/Persons/${personId}/AsSupporter`;
  const res = await fetch(url, {
    method: 'GET',
    headers: getHeaders(auth),
  });

  if (!res.ok) {
    throw new MyPageError(res.status, 'Failed to fetch supporter from server', url);
  }
  const person = await res.json();
  return person as CEE.Supporter;
}

/**
 * For some reason EmailConsent, SmsConsent and PushConsent and Lead
 * are not optional and need to be set in order to update a person.
 * They don't make any sense in this context. But they are required.
 *
 * Another field that the documentation says is optional
 * but in reality it is required, is CEE.Person.Id.
 * Even though we specify it in the url...
 */
export async function updateSupporter(
  personId: string,
  supporter: CEE.Supporter,
  auth: AuthOptions
): Promise<CEE.Supporter> {
  const url = `${REACT_APP_CEE_BASE_URL}/api/v2/Persons/${personId}/AsSupporter`;
  const res = await putWithErrorHandling(url, getHeaders(auth), supporter, () =>
    getSupporter(personId, auth)
  );

  if (!res.ok) {
    throw new MyPageError(res.status, 'Failed to update supporter on server', url);
  }

  const person = await res.json();
  return person as CEE.Supporter;
}

/**
 * These are highly internal and don't have anything useful
 * for referencing teams in firestore.
 * They do have a ShortName, but they are not the same as
 * team abbrv found in firestore.
 */
export async function getTeams(auth: AuthOptions): Promise<CEE.Team[]> {
  const url = `${REACT_APP_CEE_BASE_URL}/api/v2/Teams`;
  const res = await fetch(url, {
    method: 'GET',
    headers: getHeaders(auth),
  });

  if (!res.ok) {
    throw new MyPageError(res.status, 'Failed to fetch teams from server', url);
  }
  const teams = (await res.json()).Data;
  return teams as CEE.Team[];
}

/**
 * There is something called SubscriptionStatus that you might think would be enough
 * But no. SubscriptionStatus does not have information about the next payment for ex.
 */
export async function getSubscription(
  personId: string,
  auth: AuthOptions
): Promise<CEE.Subscription | null> {
  const url = `${REACT_APP_CEE_BASE_URL}/api/v2/Subscriptions/GetSubscriptionByPersonId/${personId}`;
  const res = await fetch(url, {
    method: 'GET',
    headers: getHeaders(auth),
  });

  if (!res.ok) {
    throw new MyPageError(res.status, 'Failed to fetch subscription from server', url);
  }

  const subs = (await res.json()).Data;
  return subs.length > 0 ? (subs[0] as CEE.Subscription) : null;
}

export async function cancelSubscription(id: string, auth: AuthOptions): Promise<CEE.Subscription> {
  const url = `${REACT_APP_CEE_BASE_URL}/api/v2/Subscriptions/CancelSubscription/${id}`;
  const res = await fetch(url, {
    method: 'PUT',
    headers: getHeaders(auth),
  });

  if (!res.ok) {
    throw new MyPageError(res.status, 'Failed to cancel subscription on server', url);
  }

  const sub = await res.json();
  return sub as CEE.Subscription;
}

export async function getProducts(auth: AuthOptions): Promise<CEE.Product[]> {
  const url = `${REACT_APP_CEE_BASE_URL}/api/v2/Products`;
  const res = await fetch(url, {
    method: 'GET',
    headers: getHeaders(auth),
  });

  if (!res.ok) {
    throw new MyPageError(res.status, 'Failed to fetch products from server', url);
  }
  const products = await res.json();
  return products as CEE.Product[];
}

type PersonConsent = {
  PersonId: string;
  ConsentId?: string;
  consent?: boolean;
  Etag?: string; // Required for update
};

type ChannelConsent = {
  Id: string;
  Channel: 'SMS' | 'EMAIL';
  Sender: string;
  Name: string; // same as sender
  TeamId: string;
};

/**
 * This is the best we can do given the current api.
 * It is not enough to get the personConsents.
 * You would think that because it has a DisplayName.
 * But no.
 * We have to get all available consents for the channels we want (SMS, EMAIL)
 * in order to later cross reference them into a model that works for the UI
 * and that we can use to update the personConsent.
 *
 * The original code is even worse, it used to do
 * GET /api/v2/Persons/GetManyConsent?limit=200
 * and cross reference with the personConsents.
 * What happens if we have more than 200 personConsents?
 * Why fetch consents that we are not interested in?
 * Why not just have the required fields in the personConsent?
 *
 * Many questions. few answers.
 */
async function fetchConsents(personId: string, auth: AuthOptions) {
  const results = await Promise.all([
    fetch(`${REACT_APP_CEE_BASE_URL}/api/v2/Persons/${personId}/PersonConsent`, {
      method: 'GET',
      headers: getHeaders(auth),
    }),
    fetch(`${REACT_APP_CEE_BASE_URL}/api/v2/Persons/GetConsentsByChannel?channel=EMAIL`, {
      method: 'GET',
      headers: getHeaders(auth),
    }),
    fetch(`${REACT_APP_CEE_BASE_URL}/api/v2/Persons/GetConsentsByChannel?channel=SMS`, {
      method: 'GET',
      headers: getHeaders(auth),
    }),
  ]);

  const [mine, email, sms] = await Promise.all(
    results.map(async (res) => {
      if (!res.ok) {
        throw new MyPageError(res.status, 'Failed to fetch consents from server');
      }
      return (await res.json()).Data;
    })
  );
  return {
    mine: mine as PersonConsent[],
    email: email as ChannelConsent[],
    sms: sms as ChannelConsent[],
  };
}

/**
 * Group person consents by sender
 */
export async function getGroupedConsents(
  personId: string,
  auth: AuthOptions
): Promise<[CEE.ConsentGroup[], CEE.Consent[], CEE.Consent[]]> {
  const { mine, email, sms } = await fetchConsents(personId, auth);
  const available = [...email, ...sms];
  const original: CEE.Consent[] = [];

  // CEE.Consent.PersonId is NOT the same as CEE.Person.Id
  // This api seems to be struggeling with the concept
  // of identifiers...
  const consentPersonId = mine[0]?.PersonId;
  const grouped = mine.reduce((acc, myConsent) => {
    const found = available.find((consent) => consent.Id === myConsent.ConsentId);
    // Person can have consents that we are not interested in. e.g PUSH
    if (found?.Sender) {
      addConsentToGroup(found.Channel, found, myConsent, acc);
      original.push(toConsentModel(myConsent, found));
    }
    return acc;
  }, [] as Partial<CEE.ConsentGroup>[]);

  // Backfill missing consents
  addChannelConsentMissingInMine(grouped, available, consentPersonId, 'SMS');
  addChannelConsentMissingInMine(grouped, available, consentPersonId, 'EMAIL');

  return [
    grouped as CEE.ConsentGroup[],
    original,
    available.map((c) => toConsentModel({ PersonId: consentPersonId }, c)),
  ];
}

/**
 * Sometimes the person consents can include email consents for some
 * team but be missing sms consents for that team completely.
 * We want all the available channels to be in the group, but have `consent = false`
 * if missing from the person consents.
 */
function addChannelConsentMissingInMine(
  grouped: Partial<CEE.ConsentGroup>[],
  available: ChannelConsent[],
  consentPersonId: string,
  channel: 'SMS' | 'EMAIL'
) {
  grouped
    .filter((group) => !group[channel])
    .forEach((group) => {
      const found = available.find((c) => c.Channel === channel && c.Sender === group.name);
      if (found) {
        group[channel] = toConsentModel({ PersonId: consentPersonId }, found);
      }
    });
}

function addConsentToGroup(
  channel: 'SMS' | 'EMAIL',
  channelConsent: ChannelConsent,
  personConsent: PersonConsent,
  acc: Partial<CEE.ConsentGroup>[]
) {
  const found = acc.find((g) => g.name === channelConsent.Sender);
  if (found) {
    found[channel] = toConsentModel(personConsent, channelConsent);
  } else {
    acc.push({
      name: channelConsent.Sender,
      [channel]: toConsentModel(personConsent, channelConsent),
    });
  }
}

function toConsentModel(personConsent: PersonConsent, channelConsent: ChannelConsent): CEE.Consent {
  return {
    TeamId: channelConsent.TeamId,
    Channel: channelConsent.Channel,
    Name: channelConsent.Sender,
    PersonId: personConsent.PersonId,
    ConsentId: personConsent.ConsentId || channelConsent.Id,
    consent: personConsent.consent || false,
    Etag: personConsent.Etag,
    Source: 'MyPage',
  };
}

export async function addConsent(
  personId: string,
  consent: CEE.Consent,
  auth: AuthOptions
): Promise<void> {
  const url = `${REACT_APP_CEE_BASE_URL}/api/v2/Persons/${personId}/PersonConsent`;
  const res = await fetch(url, {
    method: 'POST',
    headers: getHeaders(auth),
    body: JSON.stringify(consent),
  });

  if (!res.ok) {
    throw new MyPageError(res.status, 'Failed to add consent to server', url);
  }
}

/**
 * The Api docs say Etage is optional, but it is required for updating.
 */
export async function updateConsent(
  personId: string,
  consent: CEE.Consent,
  auth: AuthOptions
): Promise<void> {
  const url = `${REACT_APP_CEE_BASE_URL}/api/v2/Persons/${personId}/PersonConsent`;
  const res = await fetch(url, {
    method: 'PUT',
    headers: getHeaders(auth),
    body: JSON.stringify(consent),
  });

  if (!res.ok) {
    throw new MyPageError(res.status, 'Failed to update consent to server', url);
  }
}

function sleep(ms: number) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export async function getCardInfo(): Promise<CEE.CardInfo> {
  await sleep(700);
  return Promise.resolve({
    number: 'fake **** **** 6914',
    provider: 'mastercard',
  });
}

export async function getOrders(personId: string, auth: AuthOptions): Promise<CEE.Order[]> {
  const url = `${REACT_APP_CEE_BASE_URL}/api/billing/v3/orders/${personId}?offset=0`;
  const res = await fetch(url, {
    method: 'GET',
    headers: getHeaders(auth),
  });

  if (!res.ok) {
    throw new MyPageError(res.status, 'Failed to get orders', url);
  }

  const orders: CEE.Orders = await res.json();
  return orders.Data;
}

type deleteAccountRes = {
  statusCode: number;
};

/**
 * Delete account in CEE.
 */
export async function deleteAccount(email: string, auth: AuthOptions): Promise<deleteAccountRes> {
  const url = `${REACT_APP_CEE_BASE_URL}/api/v2/Authentication/User/DeleteAccount?email=${encodeURIComponent(
    email
  )}`;
  const res = await fetch(url, {
    method: 'DELETE',
    headers: getHeaders(auth),
  });

  if (!res.ok) {
    throw new MyPageError(res.status, 'Failed to delete account', url);
  }

  return {
    statusCode: res.status,
  };
}

/**
 * Tickets
 */
export async function getTickets(personId: string, fromDate: string, auth: AuthOptions) {
  const url = `${REACT_APP_CEE_BASE_URL}/api/v2/Tickets?personId=${personId}&fromDate=${fromDate}`;
  const res = await fetch(url, {
    method: 'GET',
    headers: getHeaders(auth),
  });

  if (!res.ok) {
    throw new MyPageError(res.status, 'Failed to get tickets', url);
  }

  const tickets: CEE.Ticket[] = await res.json();
  return tickets;
}

/**
 * Ticket QR Image
 */
export async function getQrImage(
  entranceCode: string,
  type: 'qr' | 'aztec' | 'datamatrix' | 'code128' | 'pdf417',
  auth: AuthOptions
) {
  const url = `${REACT_APP_CEE_BASE_URL}/api/v2/CodeRepresentations?code=${entranceCode}&representationType=${type}`;
  const res = await fetch(url, {
    method: 'GET',
    headers: getHeaders(auth),
  });

  if (!res.ok) {
    throw new MyPageError(res.status, 'Failed to get ticket QR image', url);
  }

  const qrImage: string = await res.json();
  return qrImage;
}
