import * as React from 'react';
import { DefaultTheme, ThemeContext } from 'styled-components';
import Select, { SingleValue, StylesConfig, Theme } from 'react-select';
import { useMediaQuery } from 'react-responsive';
import { useHistory, useLocation } from 'react-router-dom';
import { device } from 'style/device';
import I18nContext from 'I18nContext';
import { AppRoute } from 'types/types';
import { maxDesktopHeader } from './HeaderNavigation';

type Option = {
  label: string;
  value: string;
  isLogout?: boolean;
};

export type Props = {
  routes: AppRoute[];
  showLogout: boolean;
  onSignOut: () => Promise<void>;
};

function HeaderMenu({ routes, showLogout, onSignOut }: Props) {
  const history = useHistory();
  const location = useLocation();
  const appTheme = React.useContext(ThemeContext) as DefaultTheme;
  const { strings } = React.useContext(I18nContext);

  const isTabletOrBigger = useMediaQuery({ query: device.minTablet });
  const isMobileOrBigger = useMediaQuery({ query: device.minMobile });
  const isTabletOrSmaller = useMediaQuery({ query: maxDesktopHeader });

  const menuRoutes = isTabletOrSmaller ? routes : routes.filter((route) => route.shownInMenu);

  const defaultOption: Option[] =
    showLogout && isTabletOrSmaller
      ? [{ label: strings.logOut, value: 'logout', isLogout: true }]
      : [];

  const options: Option[] = menuRoutes
    .map((route) => ({ label: route.title, value: route.path }))
    .concat(defaultOption);

  const styles: StylesConfig<Option> = {
    valueContainer: (base) => ({ ...base, paddingRight: 0, fontSize: 16 }),
    singleValue: (base) => ({ ...base, color: '#fff' }),
    placeholder: (base) => ({ ...base, color: '#fff' }),
    control: (base) => ({
      ...base,
      boxShadow: 'none',
      backgroundColor: appTheme.colors.primary,
      fontFamily: appTheme.fonts.bold,
      width: isTabletOrBigger ? 'auto' : isMobileOrBigger ? 162 : 122,
      border: isTabletOrSmaller ? `1px solid ${appTheme.colors.primaryLight}` : 'none',
      ':hover': { ...base[':hover'], borderColor: appTheme.colors.primaryHighlight },
    }),
    dropdownIndicator: (base) => ({
      ...base,
      color: '#fff',
      ':hover': { ...base[':hover'], color: '#fff' },
      paddingLeft: 0,
    }),
    menu: (base) => ({
      ...base,
      width: '150%',
      right: 0,
      boxShadow: appTheme.boxShadow,
      marginTop: 4,
    }),
    menuList: (base) => ({
      ...base,
      maxHeight: 'max-content',
      paddingTop: 0,
      paddingBottom: 0,
      borderRadius: appTheme.borderRadius,
    }),
    option: (base, state) => {
      const regular = {
        ...base,
        padding: 20,
        fontFamily: appTheme.fonts.bold,
        cursor: 'pointer',
      };

      if (state.data.isLogout) {
        return {
          ...regular,
          color: 'inherit',
          textAlign: 'center',
          backgroundColor: appTheme.colors.border,
          ':hover': {
            backgroundColor: appTheme.colors.border,
          },
          ':active': {
            backgroundColor: appTheme.colors.border,
          },
        };
      }
      return {
        ...regular,
        transition: `all ${appTheme.transitionTiming}`,
      };
    },
  };

  const getTheme = (theme: Theme): Theme => ({
    ...theme,
    borderRadius: appTheme.borderRadius,
    colors: {
      ...theme.colors,
      primary: appTheme.colors.primaryLight,
      primary50: appTheme.colors.background, // click option
      primary25: appTheme.colors.background, // hover option
    },
  });

  const onSelectionChanged = (opt: SingleValue<Option>) => {
    if (opt?.isLogout) {
      onSignOut();
    } else {
      history.push(opt?.value || routes[0].path);
    }
  };

  return (
    <Select<Option>
      value={options.find((o) => o.value === location.pathname) || null}
      placeholder={strings.menu}
      options={options}
      onChange={onSelectionChanged}
      components={{ IndicatorSeparator: null }}
      styles={styles}
      theme={getTheme}
      isSearchable={false}
    />
  );
}

export default HeaderMenu;
