import * as React from 'react';
import { createRoot } from 'react-dom/client';
import App from './App';
import * as Sentry from '@sentry/react';
import reportWebVitals from './reportWebVitals';
import { initializeLogtail } from 'utils/logger';

// Init logging to Better Stack log tail
initializeLogtail();

Sentry.init({
  dsn: 'https://b5a2be582c2142d8a2a40c5e01df9610@o543475.ingest.us.sentry.io/5690664',
  integrations: [
    Sentry.browserTracingIntegration(),
    // https://docs.sentry.io/platforms/javascript/configuration/filtering/#using-thirdpartyerrorfilterintegration
    Sentry.thirdPartyErrorFilterIntegration({
      filterKeys: ['sef-mypage'],
      behaviour: 'drop-error-if-contains-third-party-frames',
    }),
  ],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: process.env.REACT_APP_SENTRY_ENV === 'production' ? 0.1 : 1.0,
  environment: process.env.REACT_APP_SENTRY_ENV,
  release: `sef-mypage@${process.env.REACT_APP_SENTRY_ENV}`,
});

const root = createRoot(document.getElementById('root')!);
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
