import * as React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { size } from 'style/device';
import styled from 'styled-components';
import HeaderMenu from './HeaderMenu';
import HeaderButton from './HeaderButton';
import { AppRoute } from 'types/types';

export const maxDesktopHeader = `(max-width: ${size.desktop + 100}px)`;
const maxWidthDesktop = 900;
const maxWidthMobile = 500;

const Container = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  max-width: ${maxWidthDesktop}px;

  @media ${maxDesktopHeader} {
    width: 100%;
    max-width: ${maxWidthMobile}px;
    justify-content: end;
  }
`;

const ContentLeft = styled.div`
  display: flex;
  flex-shrink: 0;
  align-items: center;
  margin: 0 8px;

  @media ${maxDesktopHeader} {
    margin: 0px;
  }
`;

const ContentRight = styled.div`
  display: flex;
  justify-content: end;
  flex: 1;

  @media ${maxDesktopHeader} {
    display: none;
  }
`;

const HeaderLink = styled.a<{ $selected?: boolean }>`
  font-size: 16px;
  font-family: ${({ theme }) => theme.fonts.bold};
  color: #fff;
  margin: 0 16px;
  user-select: none;

  border-bottom: 3px solid ${({ theme }) => theme.colors.accent};
  border-bottom-width: ${({ $selected }) => ($selected ? '3px' : '0px')};
  margin-bottom: ${({ $selected }) => ($selected ? '-3px' : '0px')};

  @media ${maxDesktopHeader} {
    display: none;
  }

  &:hover {
    border-bottom: 3px solid ${({ theme }) => theme.colors.accent};
    margin-bottom: -3px;
  }
`;

export type Props = {
  routes: AppRoute[];
  showLogout: boolean;
  onSignOut: () => Promise<void>;
};

function HeaderNavigation({ routes, showLogout, onSignOut }: Props) {
  const history = useHistory();
  const location = useLocation();

  const headerRoutes = routes.filter((route) => route.shownInMenu !== true);
  const selectedRoute = routes.find((route) => route.path === location.pathname);

  const onLink = (path: string) => {
    history.push(path);
  };

  return (
    <Container>
      <ContentLeft>
        {headerRoutes.map((route) => (
          <HeaderLink
            key={route.path}
            onClick={() => onLink(route.path)}
            $selected={selectedRoute?.path === route.path}
          >
            {route.title}
          </HeaderLink>
        ))}
        <HeaderMenu routes={routes} showLogout={showLogout} onSignOut={onSignOut} />
      </ContentLeft>
      <ContentRight>
        <HeaderButton onClick={onSignOut}></HeaderButton>
      </ContentRight>
    </Container>
  );
}

export default HeaderNavigation;
