import * as React from 'react';
import { device } from 'style/device';
import { H2 } from 'style/text';
import styled from 'styled-components';
import Spinner from 'components/Spinner';
import I18nContext from 'I18nContext';

const Overlay = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: ${({ theme }) => theme.colors.primaryDark};
`;

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

const Heading = styled(H2)`
  color: #fff;
  margin-top: 10px;
  margin-bottom: 6px;

  @media ${device.minTablet} {
    margin-top: 16px;
    margin-bottom: 8px;
  }
`;

const Loader = styled(Spinner)`
  color: ${({ theme }) => theme.colors.accent};
`;

const Text = styled.p`
  color: #fff;
  margin: 0;
`;

type Props = {
  heading?: string;
  description?: string;
};

function PageLoader({ heading, description }: Props) {
  const { strings } = React.useContext(I18nContext);
  const title = heading || strings.loading;

  return (
    <Overlay>
      <Container>
        <Loader />
        <Heading>{title}</Heading>
        {description && <Text>{description}</Text>}
      </Container>
    </Overlay>
  );
}

export default PageLoader;
