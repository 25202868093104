import * as React from 'react';
import I18nContext from 'I18nContext';
import { POPA } from 'api/popa/types';
import styled from 'styled-components';
import { CEE } from 'api/cee/types';
import moment from 'moment';
import { TicketIcon } from 'components/Icon';
import TicketLogo from './TicketLogo';
import { device } from 'style/device';
import { useMediaQuery } from 'react-responsive';

const Card = styled.button`
  display: flex;
  flex-direction: row;
  align-items: center;

  width: 100%;
  overflow: hidden;
  padding: 15px;
  margin-bottom: 15px;

  box-sizing: border-box;

  border-radius: 8px;
  border: 1px solid #e2dde7;
  box-shadow: 0px 15px 10px 0px rgba(0, 0, 0, 0.1), 0px 6px 4px 0px rgba(0, 0, 0, 0.05);
  background-color: transparent;
  transition: box-shadow ${({ theme }) => theme.transitionTiming};

  &:disabled {
    color: unset;
  }
  &:active:not([disabled]) {
    box-shadow: 0px 3px 4px 0px rgba(0, 0, 0, 0.05);
  }
  &:hover:not([disabled]) {
    border-color: ${({ theme }) => theme.colors.primaryLight};
  }
  &:focus {
    border: 2px solid ${({ theme }) => theme.colors.primary};
    padding: 14px;
  }
`;

const TextContainer = styled.div`
  display: flex;
  margin-left: 20px;
  margin-right: 20px;
  flex: 1;
  flex-shrink: 1;
  flex-direction: column;
`;

const Title = styled.h2`
  font-size: 16px;
  font-family: ${({ theme }) => theme.fonts.bold};
  color: ${({ theme }) => theme.colors.primaryDark};
  line-height: 17px;
  font-weight: normal;
  margin: 0;
  margin-bottom: 5px;
  text-align: left;

  // Text ellipse
  overflow: hidden;
  text-overflow: ellipsis;

  // Text ellipse on second row, limited support
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;

  @media ${device.maxTablet} {
    font-size: 14px;
  }
`;

const SubTitle = styled.p`
  font-size: 12px;
  font-family: ${({ theme }) => theme.fonts.regular};
  color: ${({ theme }) => theme.colors.primaryDark};
  text-align: left;
  margin: 0;
  margin-top: 5px;

  // Text ellipse
  overflow: hidden;
  text-overflow: ellipsis;
`;

const TicketContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-shrink: 0;
  align-items: center;
  justify-content: space-between;

  @media ${device.maxTablet} {
    flex-direction: column;
  }
`;

const TicketAmount = styled.h2`
  font-size: 26px;
  font-family: ${({ theme }) => theme.fonts.bold};
  color: ${({ theme }) => theme.colors.primaryDark};
  font-weight: normal;
  margin: 0;
  height: 28px;
  padding-right: 10px;

  @media ${device.maxTablet} {
    padding-right: 0;
    font-size: 20px;
    height: 22px;
  }
`;

type TicketProps = {
  tickets: CEE.Ticket[];
  teamsInfo?: POPA.TeamInfo[] | null;
  onClick?: (tickets: CEE.Ticket[]) => void;
  disabled?: boolean;
};

const Ticket = ({ tickets, teamsInfo, onClick, disabled }: TicketProps) => {
  const { strings } = React.useContext(I18nContext);
  const isTabletAndBelow = useMediaQuery({ query: device.maxTablet });

  const [mainTicket] = tickets;
  const ticketTeamId = mainTicket.TeamId;
  const teamInfo = teamsInfo?.find(({ everySportId }) => everySportId === Number(ticketTeamId));

  const subtitle =
    mainTicket.TicketType === 'season'
      ? strings.seasonsTicket
      : moment(mainTicket.Event.Date).format('dddd LL - LT');

  return (
    <Card onClick={() => onClick && onClick(tickets)} disabled={disabled}>
      <TicketLogo imageUrl={teamInfo?.logo} abbrv={teamInfo?.abbrv} bgColor={teamInfo?.colors.id} />
      <TextContainer>
        <Title>{mainTicket.Event.Name}</Title>
        <SubTitle>{subtitle}</SubTitle>
      </TextContainer>
      <TicketContainer>
        <TicketAmount>{tickets.length}</TicketAmount>
        {isTabletAndBelow ? (
          <TicketIcon width="30" height="19" />
        ) : (
          <TicketIcon width="51" height="32" />
        )}
      </TicketContainer>
    </Card>
  );
};

export default Ticket;
