export async function getGeolocationInformation() {
  const { REACT_APP_LOCATION_URL } = process.env;
  const res = await fetch(REACT_APP_LOCATION_URL, {
    method: 'GET',
  });

  return res;
}

export const loadGeolocation = async () => {
  try {
    const response = await getGeolocationInformation();
    const { status } = response;
    const validGeolocation = status === 200;

    return validGeolocation;
  } catch (error) {
    return false;
  }
};
