import { FirebaseApp } from 'firebase/app';
import React from 'react';
import { TokensData } from 'types/types';

type Props = {
  tokens?: TokensData;
  setTokens: (tokens: TokensData | undefined) => void;
  firebaseApp?: FirebaseApp;
  readyToRender?: boolean;
  appUser?: boolean;
  signOut: () => void;
};

const context: React.Context<Props> = React.createContext({
  setTokens: (_) => {},
  signOut: () => {},
});

export default context;
