import * as React from 'react';
import { H2 } from 'style/text';
import I18nContext from 'I18nContext';
import styled from 'styled-components';
import Button, { COLOR } from 'components/Button';
import Centered from 'components/Centered';
import DeleteAccount from 'components/DeleteAccount';
import AppContext from 'AppContext';
import { CEE } from 'api/cee/types';
import * as cee from 'api/cee';

const links = [
  { name: 'Fotboll Play', url: 'https://example.com' },
  { name: 'Ebiljetter/AXS biljettköp', url: 'https://example.com' },
  { name: 'Allsvenskan Fantasy', url: 'https://example.com' },
  { name: 'Klubbarnas och ligornas appar', url: 'https://example.com' },
  { name: 'Biljett i app', url: 'https://example.com' },
  { name: 'Live Partner', url: 'https://example.com' },
  { name: 'Klubb premium abonnemang (betaltjänst)', url: 'https://example.com' },
  { name: 'Highlights+ (betaltjänst)', url: 'https://example.com' },
];

const Link = styled.a`
  color: ${({ theme }) => theme.colors.primary};
`;

const List = styled.ul`
  list-style: none;
  padding-left: 24px;
`;

const ListItem = styled.li`
  height: 20px;
`;

const Spacing = styled.div<{ $large?: boolean }>`
  height: ${(props) => (props.$large ? 40 : 20)}px;
`;

const Disclaimer = styled.p`
  font-size: 12px;
  > a {
    text-decoration: underline;
  }
  @media (min-width: 768px) {
    text-align: center;
  }
`;

const MyAccount = () => {
  const { strings } = React.useContext(I18nContext);
  const { tokens } = React.useContext(AppContext);
  const [supporter, setSupporter] = React.useState<CEE.Supporter | null>(null);
  const [showDeleteAccountModal, setShowDeleteAccountModal] = React.useState(false);
  const [loadingDeleteAccount, setLoadingDeleteAccount] = React.useState(false);

  React.useEffect(() => {
    async function fetchData() {
      if (tokens?.accessToken) {
        const sup = await cee.getSupporter(tokens.PersonId, { accessToken: tokens.accessToken });
        setSupporter(sup);
      }
    }
    fetchData();
  }, [tokens]);

  const onDeleteAccount = async (e: React.MouseEvent<HTMLButtonElement>) => {
    if (showDeleteAccountModal) return;
    e.preventDefault();
    setShowDeleteAccountModal(true);
  };

  return (
    <>
      <H2>{strings.titleSubscription}</H2>
      <div dangerouslySetInnerHTML={{ __html: strings.subscriptionInfo }} />
      <div dangerouslySetInnerHTML={{ __html: strings.subscriptionLinks }} />
      <List>
        {links.map((link) => (
          <ListItem key={link.name}>
            <div>{link.name}</div>
          </ListItem>
        ))}
      </List>
      <Spacing />
      <H2>{strings.titleCancelSubscription}</H2>
      <div dangerouslySetInnerHTML={{ __html: strings.cancelSubscriptionInfo }} />
      <Spacing $large />
      <Centered>
        <Button
          color={COLOR.DANGER}
          key="removeaccountBtn"
          onClick={onDeleteAccount}
          loading={loadingDeleteAccount}
          disabled={!supporter}
        >
          {strings.removeAccount1}
        </Button>
      </Centered>
      <Spacing />
      <Disclaimer>
        <>
          {strings.gdprNotice}{' '}
          <Link href={`mailto: ${strings.gdprEmail}`}>{strings.gdprEmail}</Link>
        </>
      </Disclaimer>
      {showDeleteAccountModal && supporter && (
        <DeleteAccount
          supporter={supporter}
          onClose={() => setShowDeleteAccountModal(false)}
          onLoading={(loading) => setLoadingDeleteAccount(loading)}
        />
      )}
    </>
  );
};

export default MyAccount;
