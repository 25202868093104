import * as React from 'react';
import I18nContext from 'I18nContext';
import Button, { COLOR } from 'components/Button';
import styled from 'styled-components';
import { H1, H2 } from 'style/text';

const Container = styled.main`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100vh;
  padding: 0 32px;
  overflow: hidden;
`;

const StatusCode = styled(H1)`
  font-size: 62px;
  line-height: 89px;
  margin-bottom: 6px;
`;

const Message = styled(H2)`
  font-size: 20px;
  line-height: 29px;
  margin-bottom: 9px;
`;

type ErrorPageProps = {
  statusCode?: number | string;
  message?: string;
  details?: string;
  resetError?: () => void;
};

function ErrorPage({ statusCode = 500, message, details, resetError }: ErrorPageProps) {
  const { strings } = React.useContext(I18nContext);

  const onBackToStart = () => {
    resetError && resetError();
    window.location.href = window.location.origin;
  };

  return (
    <Container>
      <StatusCode>{statusCode}</StatusCode>
      <Message>{message || strings.unknownError}</Message>
      <p>{details}</p>

      <Button fullWidth color={COLOR.SUCCESS} onClick={onBackToStart}>
        {strings.backToStart}
      </Button>
    </Container>
  );
}

export default ErrorPage;
