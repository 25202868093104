import * as React from 'react';
import styled from 'styled-components';
import I18nContext from 'I18nContext';

const Button = styled.button`
  outline: none;
  font-size: 14px;
  font-family: ${({ theme }) => theme.fonts.bold};
  background-color: transparent;
  padding: 6px 12px;
  color: #fff;
  border: 1px solid ${({ theme }) => theme.colors.primaryLight};
  border-radius: ${({ theme }) => theme.borderRadius}px;
  height: 38px;
  cursor: pointer;

  &:hover {
    border-color: ${({ theme }) => theme.colors.primaryHighlight};
  }
  &:focus {
    border-color: ${({ theme }) => theme.colors.primaryHighlight};
  }
`;

type Props = {
  onClick: () => void;
};

function HeaderButton({ onClick }: Props) {
  const { strings } = React.useContext(I18nContext);
  return <Button onClick={onClick}>{strings.logOut}</Button>;
}

export default HeaderButton;
