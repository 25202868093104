import React, { useState, useEffect, useCallback } from 'react';
import { CEE } from 'api/cee/types';
import * as cee from 'api/cee';
import * as ceeV3 from 'api/cee/cee-v3';
import AppContext from 'AppContext';
import I18nContext from 'I18nContext';
import styled from 'styled-components';
import Accordion from 'components/Accordion';
import ClubSubscriptionCard from 'components/ClubSubscriptionCard';
import PageLoader from 'components/PageLoader';
import { device } from 'style/device';

const Title = styled.div`
  display: flex;
  flex-grow: 1;
  justify-content: space-between;
`;

const Container = styled.main`
  display: flex;
  justify-content: center;

  @media ${device.maxTablet} {
    justify-content: left;
  }
`;

const Name = styled.p``;
const Price = styled.p``;

const Subscription = () => {
  const { tokens } = React.useContext(AppContext);
  const { strings } = React.useContext(I18nContext);
  const [loadingClubSubscriptions, setLoadingClubSubscriptions] = useState(true);
  const [subscriptionProducts, setSubscriptionProducts] = useState<CEE.PersonProductV3[]>([]);

  const fetchClubSubscriptions = useCallback(async () => {
    if (!tokens) return;
    setLoadingClubSubscriptions(true);
    const products = await ceeV3.getPersonSubscriptionProducts(tokens.PersonId, tokens.accessToken);
    setSubscriptionProducts(products);
    setLoadingClubSubscriptions(false);
  }, []);

  useEffect(() => {
    async function fetchData() {
      fetchClubSubscriptions();
    }
    fetchData();
  }, []);

  const onActivate = (checkoutUrl: string) => {
    window.open(checkoutUrl, '_blank')?.focus();
  };

  const onChangePayment = (checkoutUrl: string) => {
    window.open(checkoutUrl, '_blank')?.focus();
  };

  const onReadMore = (readMoreUrl: string) => {
    window.open(readMoreUrl, '_blank')?.focus();
  };

  const onCancelSubscription = () => {
    fetchClubSubscriptions();
  };

  const loading = loadingClubSubscriptions;

  if (loading) {
    return <PageLoader />;
  }

  if (!loading && subscriptionProducts.length === 0) {
    return <Container>{strings.subscriptionsEmpty}</Container>;
  }

  return (
    <>
      {subscriptionProducts.map((product) => (
        <Accordion
          key={product.Product.Id}
          title={
            <Title>
              <Name>{product.Product.DisplayName}</Name>
              <Price>{`${product.Product.PricePlans[0].PriceAmount} ${strings.currencyPerMonth}`}</Price>
            </Title>
          }
        >
          <ClubSubscriptionCard
            product={product}
            onActivate={onActivate}
            onChangePayment={onChangePayment}
            onReadMore={onReadMore}
            onCancelSubscription={onCancelSubscription}
          ></ClubSubscriptionCard>
        </Accordion>
      ))}
    </>
  );
};

export default Subscription;
