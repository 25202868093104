import * as React from 'react';
import AppContext from 'AppContext';
import { useQuery } from '@tanstack/react-query';
import {
  getFirestore,
  doc,
  collection,
  query,
  where,
  getDoc,
  getDocs,
  orderBy,
  WhereFilterOp,
  OrderByDirection,
  DocumentData,
} from 'firebase/firestore';

export const DEFAULT_CACHE_TIME = 1000 * 60 * 15; // 15min
export const DEFAULT_STALE_TIME = 1000 * 60 * 15; // 15min

export type QueryConfig = {
  filter?: {
    field: string;
    operator: WhereFilterOp;
    value: string | number;
  };
  orderBy?: {
    field: string;
    direction: OrderByDirection;
  };
};

export const useDocument = <Type>(queryKey: any[], path: string) => {
  const { firebaseApp } = React.useContext(AppContext);
  const firestore = getFirestore(firebaseApp!);

  const { isLoading, error, data } = useQuery({
    queryKey,
    queryFn: async () => {
      const docRef = doc(firestore, path);
      const querySnapshot = await getDoc(docRef);
      return querySnapshot.data();
    },
    staleTime: DEFAULT_STALE_TIME,
  });

  return [data, isLoading, error] as [Type | undefined, boolean, Error | null];
};

export const useCollection = <Type>(queryKey: any[], path: string, config?: QueryConfig) => {
  const { firebaseApp } = React.useContext(AppContext);
  const firestore = getFirestore(firebaseApp!);

  const { isLoading, error, data } = useQuery({
    queryKey,
    queryFn: async () => {
      let q = query(collection(firestore, path));
      if (config?.filter) {
        q = query(q, where(config.filter.field, config.filter.operator, config.filter.value));
      }
      if (config?.orderBy) {
        q = query(q, orderBy(config.orderBy.field, config.orderBy.direction));
      }
      const querySnapshot = await getDocs(q);
      return querySnapshot.docs.map((doc) => doc.data());
    },
    staleTime: DEFAULT_STALE_TIME,
  });

  return [data, isLoading, error] as [Type[] | undefined, boolean, Error | null];
};
