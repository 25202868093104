import * as React from 'react';
import PageLoader from 'components/PageLoader';
import i18n from 'i18n';
import { POPA } from 'api/popa/types';
import { H2 } from 'style/text';
import styled from 'styled-components';
import Divider from 'components/Divider';
import { useDocument, useCollection } from 'hooks/firebase';

const Article = styled.div`
  margin-bottom: 16px;

  p {
    margin: 0px 0px 16px 0px;
    line-height: 21px;
  }

  a {
    color: ${({ theme }) => theme.colors.primary};
    &:hover {
      text-decoration: underline;
    }
  }

  h3 {
    color: ${({ theme }) => theme.colors.primaryDark};
    font-family: ${({ theme }) => theme.fonts.bold};
    padding: 0;
    font-size: 16px;
    text-align: left;
    font-weight: normal;
  }
`;

type FaqVariables = Record<string, string>;

const FAQPage = () => {
  const faqVariablesPath = `faq/${i18n.supportedLanguages[0]}`; // TODO: Choose language here if multiple languages are supported
  const faqArticlesPath = `${faqVariablesPath}/articles`;

  const [variables, variablesLoading] = useDocument<FaqVariables>(
    ['faqVariables'],
    faqVariablesPath
  );
  const [articles, articlesLoading] = useCollection<POPA.FaqArticle>(
    ['faqArticles'],
    faqArticlesPath
  );

  if (variablesLoading || articlesLoading) {
    return <PageLoader />;
  }

  if (!variables || !articles) {
    return null;
  }

  return (
    <>
      {articles.map((article, index, arr) => {
        let html = article.html;
        Object.entries(variables).forEach(
          ([key, value]) => (html = html.replaceAll(`{{${key}}}`, value))
        );

        return (
          <React.Fragment key={article.slug}>
            <Article>
              <H2>{article.title}</H2>
              <div dangerouslySetInnerHTML={{ __html: html }} />
            </Article>
            {index !== arr.length - 1 && <Divider />}
          </React.Fragment>
        );
      })}
    </>
  );
};

export default FAQPage;
