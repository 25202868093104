import * as React from 'react';
import { ThemeProvider } from 'styled-components';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { FirebaseApp, FirebaseOptions, initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import GlobalStyle from 'style/globalStyle';
import theme from 'style/theme';
import { SnackbarProvider } from 'notistack';
import { getUrlParams } from 'utils';
import PromiseErrorBoundary from 'components/PromiseErrorBoundary';
import { GTMProvider } from '@elgorditosalsero/react-gtm-hook';
import { ISnippetsParams } from '@elgorditosalsero/react-gtm-hook/dist/models/GoogleTagManager';
import Footer from 'components/Footer';
import Header from 'components/Header';
import * as popa from 'api/popa';
import { POPA } from 'api/popa/types';
import AppContext from 'AppContext';
import I18nContext from 'I18nContext';
import i18n from 'i18n';
import Page from 'pages/Page';
import ErrorPage from 'pages/ErrorPage';
import Login from 'pages/Login';
import AccountInfo from 'pages/AccountInfo';
import MyAccount from 'pages/MyAccount';
import Subscription from 'pages/Subscription';
import FAQPage from 'pages/FAQ';
import Consents from 'pages/Consents';
import Payments from 'pages/Payments';
import Tickets from 'pages/Tickets';
import Partner from 'pages/Partner';
import AppGTMStart from 'components/AppGTMStart';
import moment from 'moment';
import * as cee from 'api/cee';
import * as Sentry from '@sentry/react';
import 'moment/locale/sv';
import { loadGeolocation } from 'api/location-api';
import { MyPageError } from 'MyPageError';
import { logError } from 'utils/logger';
import { AppRoute, TokensData } from 'types/types';

moment.locale('sv');

const queryClient = new QueryClient();

const {
  REACT_APP_FIREBASE_API_KEY,
  REACT_APP_FIREBASE_AUTH_DOMAIN,
  REACT_APP_FIREBASE_DATABASE_URL,
  REACT_APP_FIREBASE_PROJECT_ID,
  REACT_APP_FIREBASE_STORAGE_BUCKET,
  REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  REACT_APP_FIREBASE_APP_ID,
  REACT_APP_FIREBASE_MEASUREMENT_ID,
} = process.env;

const opts: FirebaseOptions = {
  apiKey: REACT_APP_FIREBASE_API_KEY,
  authDomain: REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: REACT_APP_FIREBASE_DATABASE_URL,
  projectId: REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: REACT_APP_FIREBASE_APP_ID,
  measurementId: REACT_APP_FIREBASE_MEASUREMENT_ID,
};

const initialGtmParams: ISnippetsParams = {
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  id: process.env.REACT_APP_GOOGLE_TAG_MANAGER_ID!,
  injectScript: false,
};

if (
  process.env.REACT_APP_GOOGLE_TAG_MANAGER_AUTH &&
  process.env.REACT_APP_GOOGLE_TAG_MANAGER_PREVIEW
) {
  initialGtmParams.environment = {
    gtm_auth: process.env.REACT_APP_GOOGLE_TAG_MANAGER_AUTH,
    gtm_preview: process.env.REACT_APP_GOOGLE_TAG_MANAGER_PREVIEW,
  };
}

function App() {
  const [firebaseApp, setFirebaseApp] = React.useState<FirebaseApp>();
  const [tokens, setTokens] = React.useState<TokensData | undefined>();
  const [readyToRender, setReadyToRender] = React.useState<boolean>();
  const [gtmParams, setGtmParams] = React.useState<ISnippetsParams>(initialGtmParams);
  const { href } = window.location;
  const { isApp } = getUrlParams(href);
  const appUser = isApp === '1';
  const isLoggedIn = !!tokens;
  const [showPartner, setShowPartner] = React.useState<boolean>(false);
  const [showSubscription, setShowSubscription] = React.useState<boolean>(false);
  const strings = i18n.sv; // TODO: Choose language here if multiple languages are supported
  const initOnce = React.useRef(false);

  const tokenHandler = async (data: any) => {
    const user = await cee.getUser({ accessToken: data.accessToken });
    setTokens({
      ...data,
      ...user,
    });
  };

  React.useEffect(() => {
    async function fetchData() {
      if (tokens?.accessToken) {
        try {
          const accounts = await cee.getLinkedAccounts(tokens.PersonId, {
            accessToken: tokens.accessToken,
          });

          if (accounts?.length > 0) {
            setShowPartner(true);
          }
        } catch (error) {
          // Do nothing
        }
        try {
          const validLocation = await loadGeolocation();
          setShowSubscription(validLocation);
        } catch (error) {
          console.error('Failed to fetch geolocation', error);
        }
      }
    }
    fetchData();
  }, [tokens]);

  React.useEffect(() => {
    // Delay injecting GTM until later after Cookiebot has been initialized
    const timeoutRef = setTimeout(() => {
      setGtmParams({
        ...initialGtmParams,
        injectScript: true,
      });
    }, 1000); // Arbitrary timeout value

    return () => clearTimeout(timeoutRef);
  }, []);

  React.useEffect(() => {
    if (!initOnce.current) {
      const app = initializeApp(opts);
      setFirebaseApp(app);

      const { ConnectedLeagueIntegration } = window as any;
      if (ConnectedLeagueIntegration && appUser) {
        ConnectedLeagueIntegration.addAccessTokenEventHandler(tokenHandler);
      }

      setReadyToRender(true);
      initOnce.current = true;
    }
  }, [appUser]);

  const routes = [
    {
      path: '/account',
      title: strings.info,
      component: AccountInfo,
    },
    {
      path: '/myaccount',
      title: strings.myAccount,
      component: MyAccount,
    },
    {
      path: '/',
      title: strings.tickets,
      component: Tickets,
    },
    {
      path: '/subscription',
      title: strings.subscription,
      component: Subscription,
      isHidden: !showSubscription,
    },
    {
      path: '/payments',
      title: strings.paymentHistroy,
      component: Payments,
    },
    {
      path: '/consents',
      title: strings.consents,
      component: Consents,
      isHidden: true,
    },
    {
      path: '/partner',
      title: strings.partner,
      component: Partner,
      isHidden: !showPartner,
      shownInMenu: true,
    },
    {
      path: '/faq',
      title: strings.faq,
      component: FAQPage,
      isPublic: true,
      shownInMenu: true,
    },
  ] satisfies AppRoute[];

  const signOut = async () => {
    if (isLoggedIn) {
      if (isApp) {
        const { ConnectedLeagueIntegration } = window as any;
        ConnectedLeagueIntegration?.logoutFromApp();
        return;
      }
      const firebaseAuth = getAuth(firebaseApp);
      await firebaseAuth.signOut();
      const redirectUrl = await popa.getRedirectToLogoutUrl(tokens.idToken, href);
      window.location.href = redirectUrl;
    }
  };

  return (
    <GTMProvider state={gtmParams}>
      <AppGTMStart />
      <QueryClientProvider client={queryClient}>
        <AppContext.Provider
          value={{ firebaseApp, tokens, setTokens, readyToRender, appUser, signOut }}
        >
          <I18nContext.Provider value={{ strings }}>
            <ThemeProvider theme={theme}>
              <SnackbarProvider maxSnack={1} autoHideDuration={4000}>
                <GlobalStyle />
                <Router>
                  <Sentry.ErrorBoundary
                    onError={(error: any) => {
                      logError(`MyPageError - ${error.message || 'Unknown error'}`, {
                        errorMessage: error,
                      });
                    }}
                    fallback={({ error, resetError }) => {
                      const myPageError = error as MyPageError;
                      return (
                        <ErrorPage
                          statusCode={myPageError.code}
                          message={myPageError.message}
                          resetError={resetError}
                        />
                      );
                    }}
                  >
                    <Header
                      routes={routes.filter((r) => !r.isHidden)}
                      showLogout={!appUser}
                      onSignOut={signOut}
                    />
                    <Switch>
                      {routes.map((route) => (
                        <Route
                          exact
                          key={route.path}
                          path={route.path}
                          render={(props) => (
                            <>
                              {!isLoggedIn && !route.isPublic && <Login />}
                              {(isLoggedIn || (route.isPublic && readyToRender)) && (
                                <Page title={route.title}>
                                  <route.component {...props} />
                                </Page>
                              )}
                            </>
                          )}
                        />
                      ))}
                      <Route path="*">
                        <ErrorPage statusCode={404} message={strings['404Error']} />
                      </Route>
                    </Switch>
                  </Sentry.ErrorBoundary>
                </Router>
                <Footer />
              </SnackbarProvider>
            </ThemeProvider>
          </I18nContext.Provider>
        </AppContext.Provider>
      </QueryClientProvider>
    </GTMProvider>
  );
}

export default App;
