import * as React from 'react';
import styled from 'styled-components';
import HeaderLogo from './HeaderLogo';
import HeaderNavigation, { Props as NavProps } from './HeaderNavigation';

export const Height = 74;

const Container = styled.header`
  position: fixed;
  top: 0;
  width: 100%;
  height: ${Height}px;
  background-color: ${({ theme }) => theme.colors.primary};
  box-shadow: 0px 5px 0px ${({ theme }) => theme.colors.accent};
  z-index: 2;
  display: flex;
  justify-content: center;
`;

const Content = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  margin: 0 16px;
`;

type Props = NavProps;

function Header(props: Props) {
  return (
    <Container>
      <Content>
        <HeaderLogo />
        <HeaderNavigation {...props} />
      </Content>
    </Container>
  );
}
export default Header;
