import { CustomError } from 'ts-custom-error';

export class MyPageError extends CustomError {
  private requestUrl: string | undefined;

  constructor(public code: number, message?: string, url?: string) {
    super(message);
    this.requestUrl = url;
  }

  public getRequestUrl() {
    return this.requestUrl;
  }
}
