import * as React from 'react';
import styled from 'styled-components';
import { device } from '../style/device';

export enum PLACEMENT {
  BOTTOM = 'bottom',
  BOTTOM_LEFT = 'bottom-left',
  BOTTOM_RIGHT = 'bottom-right',
}

type PopupProps = {
  placement?: PLACEMENT;
  visible?: boolean;
};

const Popup = styled.div<PopupProps>`
  position: absolute;
  top: 100%;
  right: ${({ placement }) => placement === 'bottom-left' && 0};
  left: ${({ placement }) => placement === 'bottom-right' && 0};
  z-index: 1;

  background-color: #fff;
  border-radius: ${({ theme }) => theme.borderRadius}px;
  box-shadow: 0 3px 6px 1px hsl(0deg 0% 0% / 16%);
  box-sizing: border-box;
  margin-top: 6px;
  padding: 20px;
  width: 250px;
  transition: opacity 0.2s ease;

  display: none;
  opacity: 0;
  ${({ visible }) => visible && 'display: block; opacity: 1;'}
`;

const Circle = styled.a`
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  border: 1px solid ${({ theme }) => theme.colors.primaryDark};
  height: 24px;
  width: 24px;

  @media ${device.minTablet} {
    height: 26px;
    width: 26px;
  }
`;

const Icon = styled.span`
  font-family: 'Lobster Two', cursive;
  color: ${({ theme }) => theme.colors.primaryDark};
  cursor: default;
`;

type Props = { content: React.ReactNode } & PopupProps;

function Tooltip({ content, placement = PLACEMENT.BOTTOM }: Props) {
  const [visible, setVisible] = React.useState(false);
  const ref = React.useRef<HTMLAnchorElement>(null);

  function handleClickOutside(event: any) {
    if (ref.current && !ref.current.contains(event.target)) {
      setVisible(false);
    }
  }

  React.useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref]);

  return (
    <Circle onClick={() => setVisible(!visible)} ref={ref}>
      <Icon>i</Icon>
      <Popup placement={placement} visible={visible}>
        {content}
      </Popup>
    </Circle>
  );
}

export default Tooltip;
