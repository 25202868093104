import * as React from 'react';
import { useMediaQuery } from 'react-responsive';
import styled from 'styled-components';
import { device } from '../style/device';
import { H2 } from '../style/text';
import Tooltip, { PLACEMENT } from './Tooltip';

const StyledSection = styled.section`
  margin-bottom: 15px;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 15px;

  > H2 {
    margin-bottom: 0;
  }
`;

type Props = React.PropsWithChildren<{
  header?: string;
  tooltip?: string;
}>;

function Section({ header, tooltip, children }: Props) {
  const isTabOrBigger = useMediaQuery({ query: device.minTablet });
  const tooltipPlacement = isTabOrBigger ? PLACEMENT.BOTTOM : PLACEMENT.BOTTOM_LEFT;
  return (
    <StyledSection>
      {header && (
        <Header>
          <H2>{header}</H2>
          {tooltip && <Tooltip content={tooltip} placement={tooltipPlacement} />}
        </Header>
      )}
      {children}
    </StyledSection>
  );
}

export default Section;
