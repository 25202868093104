import { DefaultTheme } from 'styled-components';

const theme: DefaultTheme = {
  colors: {
    primary: '#532681',
    primaryDark: '#341058',
    primaryLight: '#8652B7',
    primaryHighlight: '#946fb7',
    accent: '#75C0E3',
    green: '#198754',
    red: '#C60000',
    background: '#F6F4F9',
    border: '#EAEAEA',
    grey: '#ccc',
    black: '#000',
    transparentGrey: 'rgba(0, 0, 0, 0.16)',
    fields: {
      background: '#F6F4F9',
      backgroundDisabled: '#E9E9E9',
      border: '#949494',
      text: '#333',
      textDisabled: '#4C4C4C',
    },
    selectOption: {
      hover: '#F6F4F9',
      selected: '#E9E9E9',
      backgroundActive: '#E9E9E9',
    },
  },
  borderRadius: 4,
  boxShadow: '0 3px 5px rgba(0, 0, 0, 0.16)',
  transitionTiming: '0.2s ease',
  fonts: {
    bold: 'Gilroy-Bold',
    regular: 'Gilroy-Regular',
  },
};

export default theme;
