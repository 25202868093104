export const size = {
  mobile: 425,
  tablet: 768,
  desktop: 1024,
};

export const device = {
  minTablet: `(min-width: ${size.tablet}px)`,
  maxTablet: `(max-width: ${size.tablet}px)`,
  minDesktop: `(min-width: ${size.desktop}px)`,
  maxDesktop: `(max-width: ${size.desktop}px)`,
  minMobile: `(min-width: ${size.mobile}px)`,
  maxMobile: `(max-width: ${size.mobile}px)`,
};
